import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import { green } from '@material-ui/core/colors'
import FaceIcon from '@material-ui/icons/Face'
import { useLogin } from './LoginContext'
import { Tooltip } from '@material-ui/core'
import { LogoutComponent, LoginUnready } from './LoginComponent'

const useStyles = makeStyles(theme => ({
  rounded: {
    color: '#fff',
    backgroundColor: green[500],
  },
  small: {
    width: 28,
    height: 28,
  },
  clickable: {
    cursor: 'pointer',
  },
  iconButton: {
    height:40,
    width:40,
  },
  barContainer:{
    display:'flex',
    alignItems:'center',
    justifyContent: 'flex-end',
  },
  loginIcon: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '24px',
    lineHeight: 1,
    letterSpacing: 'normal',
    textTransform: 'none',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    wordWrap: 'normal',
    direction: 'ltr',
    '-webkit-font-feature-settings': 'liga',
    '-webkit-font-smoothing': 'antialiased',
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
}))

export default function LoginPanel() {
  const classes = useStyles()
  const avatarRef = React.useRef(null)
  const [showLoginScreen, setShowLoginScreen] = React.useState(null)
  const [loginState] = useLogin()
  const loginReady = loginState.loginReady

  return (
    <>
      <div className={classes.iconWrapper}>
        {!loginState?.user ? null :
          <Tooltip title={!loginState?.user ? 'Sign In' : 'Account: ' + loginState?.user?.email} placement='bottom' arrow>
            <Avatar
              ref={avatarRef}
              variant='rounded'
              className={`${classes.rounded} ${classes.small} ${classes.clickable}`}
              alt={loginState?.user?.displayName ?? null}
              src={loginState?.user?.photoURL ?? null}
              onClick={() => setShowLoginScreen('logout')}
            >
              <FaceIcon />
            </Avatar>
          </Tooltip>
        }
      </div>
      {!loginReady ?
        <LoginUnready />:
        <>
          <LogoutComponent
            // open={true}
            open={showLoginScreen === 'logout'}
            onClose={() => setShowLoginScreen(null)}
          />
        </>
      }
    </>
  )
}
