import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Typography, Checkbox, Grid } from '@material-ui/core'

import { RespButton } from '../../common/buttons'
import GenericDialog from '../../common/GenericDialog'

import { useSnackbar } from 'notistack'
import { useLessonAccess, lamControl } from './LessonAccessContext'
import { displayLessonAccessInfoSnackbar } from './display-lesson-access-info-snackbar'


const useStyles = makeStyles((theme) => {
  return {
    dialogContent: {
      display: 'flex',
      flexDirection:'column',
      alignItems:'space-between',
    },
    gridContainer: {
      padding: '10px 20px',
      maxHeight: 600,
      minHeight: 400,
      maxWidth: 1200,
      minWidth: 800,
    },
    memberTabContainer: {
      height: '100%',
      width: '100%',
    },
    memberTabButton: {
      padding: '5px 10px',
      textTransform: 'none',
      borderRadius: 3,
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%',
      height: '100%',
      border: '1px solid rgba(0,0,0,0.2)',
    },
    buttonContent: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems:'center',
      gap: 5,
      width: '100%',
    },
    buttonTextContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      height: '100%',
      width: 'calc(100% - 42px)',
    },
    buttonText: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
  }
})

function DetailDialogMemberTab({ email, name, id }) {
  const classes = useStyles()
  const { selectedListUsers } = useLessonAccess()
  const { toggleUserListSelection } = lamControl

  function handleClick() {
    toggleUserListSelection(id)
  }

  const nameExists = !!name && name !== ''
  const selected = selectedListUsers.includes(id)
  return (
    <Grid
      item
      xl={3}
      md={4}
      sm={6}
      xs={12}
      style={{height: 65, minWidth: 250}}
    >
      <div
        className={classes.memberTabContainer}
        style={{backgroundColor: selected ? 'white' : 'rgba(0,0,0,0.05)'}}
      >
        <Button
          disableRipple={true}
          onClick={handleClick}
          className={classes.memberTabButton}
        >
          <div className={classes.buttonContent}>
            <Checkbox checked={selected}/>
            <div className={classes.buttonTextContainer}>
              <Typography className={classes.buttonText} noWrap={true}>{email}</Typography>
              {nameExists &&
              <Typography
                style={{fontSize: 14}}
                className={classes.buttonText}
                variant='caption'
              >
                {name}
              </Typography>}
            </div>
          </div>
        </Button>
      </div>
    </Grid>
  )
}

function DetailSelectionDialogHeaderButton() {
  const { allUserListSelected } = useLessonAccess()
  const { toggleAllUserListSelection } = lamControl

  return (
    <Button variant='outlined' onClick={toggleAllUserListSelection} style={{marginTop: 3, marginRight: 3}}>
      {allUserListSelected ? 'Deselect All' : 'Select All'}
    </Button>
  )
}

function UserSelectionDialog() {
  const classes = useStyles()
  const snacks = useSnackbar()
  const {
    detailSelectionDialogOpen,
    userList,
    selectedListUsers,
    postingCodeState,
  } = useLessonAccess()
  const {
    toggleDetailSelectionDialogOpen,
    postMultipleCodes,
    setPostingCodeState,
    onMultipleCodesSuccess,
  } = lamControl


  function onSuccess() {
    displayLessonAccessInfoSnackbar(snacks)
    snacks.enqueueSnackbar(`Successfully applied changes to ${selectedListUsers.length} user(s).`, {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      autoHideDuration: 2000,
    })
    onMultipleCodesSuccess()
    toggleDetailSelectionDialogOpen()
  }

  return (
    <GenericDialog
      open={detailSelectionDialogOpen}
      onClose={toggleDetailSelectionDialogOpen}
      keepMounted
      title={
        <Typography variant='subtitle1'>
          {'Select Users to Apply Lesson Access'}
        </Typography>
      }
      headerButtons={
        <DetailSelectionDialogHeaderButton />
      }
      buttons={
        <>
          <RespButton
            variant='contained'
            color='secondary'
            buttonComponent={Button}
            onClick={postMultipleCodes}
            successCb={onSuccess}
            status={postingCodeState}
            setStatus={setPostingCodeState}
            disabled={selectedListUsers.length === 0}
            resetStatus={!detailSelectionDialogOpen}
            style={{border: '1px solid rgba(0,0,0,0.2)'}}
            disableElevation
          >
            {`Apply changes to ${selectedListUsers.length} user(s)`}
          </RespButton>
          <Button
            onClick={toggleDetailSelectionDialogOpen}
            variant='outlined'
          >
            {'Close'}
          </Button>
        </>
      }
    >
      <div className={classes.dialogContent}>
        <Grid
          container
          spacing={1}
          alignContent='flex-start'
          className={classes.gridContainer}
        >
          {userList.map(usr => <DetailDialogMemberTab key={usr.id} {...usr}/>)}
        </Grid>
        <div/>
      </div>
    </GenericDialog>
  )
}

export { UserSelectionDialog }