import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Button } from '@material-ui/core'

import { DeleteButton, RespButton } from '../../common/buttons'
import GenericDialog from '../../common/GenericDialog'


const useStyles = makeStyles(theme => ({
  dialog: {
    maxHeight: '90%',
  },
  dialogContent: {
    overflowX: 'hidden',
    height:'200px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: 20,
    padding: '25px',
  },
  dialogHeader: {
    padding: '10px',
    height:'25px',
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  dialogFooter: {
    padding: '20px',
    height: '20px',
    backgroundColor: '#c9c9c9',
    display:'flex',
    justifyContent: 'flex-end',
    alignItems:'center',
  },
  dialogRoot: {
    maxheight:'90vh',
  },
  dialogContentContainer: {
    width:400,
    display:'flex',
    flexDirection: 'column',
    padding: '5px 0px',
  },
}))


export default function WarningDialog(props) {
  const classes = useStyles()
  const [fetchStatus, setFetchStatus] = React.useState(null)

  const handleSuccess = () => {
    props.handleClose()
    props.handleDeleteSuccess()
  }

  return (
    <GenericDialog
      keepMounted={true}
      open={props.open}
      onClose={props.handleClose}
      title={
        <Typography>
          {props.title}
        </Typography>
      }
      buttons={
        <>
          <RespButton
            color='secondary'
            buttonComponent={DeleteButton}
            onClick={props.handleDelete}
            successCb={handleSuccess}
            status={fetchStatus}
            setStatus={setFetchStatus}
            resetStatus={!props.open}
            style={{width: 100}}
          >
            Delete
          </RespButton>
          <Button
            onClick={props.handleClose}
            variant='outlined'
          >
            Cancel
          </Button>
        </>
      }
    >
      <div className={classes.dialogContentContainer}>
        <Typography variant='body1' >
          {`Are you sure you want to delete ${props.content}?`}
        </Typography>
      </div>
    </GenericDialog>
  )
}
