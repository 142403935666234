import React from 'react'
import { lessonContentDomain, AVATAR_SOURCE } from './utils'
import { useMissionPacks } from './MissionPackContext'
import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0px',
    padding: '10px 0px',
    borderBottom: `1px solid ${theme.palette.custom.quaternary}`,
    borderTop: `1px solid ${theme.palette.custom.quaternary}`,
    backgroundColor: '#F4D038',
    color: '#6e767c',
  },
  packButton: {
    display: 'flex',
    alignItems:'center',
    justifyContent: 'center',
    textTransform: 'capitalize',
  },
  selectedButton: {
    color: 'black',
    backgroundColor: '#4CAF5066',
    '&:hover': {
      backgroundColor: '#4CAF5066',
    },
  },
}))


export default function QuickFilter(props) {
  const { missionPacks } = useMissionPacks()
  const classes = useStyles()
  const [selectedPack, setSelectedPack] = React.useState(null)

  const onSelect = (pack) => {
    if (selectedPack?.id === pack.id){
      setSelectedPack(null)
      props.setFilteredRows(null)
    } else {
      const filteredRows = props.rows.filter(row => row.missionPackId === pack.id)
      props.setFilteredRows(filteredRows)
      setSelectedPack(pack)
    }
  }

  return (
    <div>
      <Typography className={classes.header} style={{height: props.height}}>
        Quick Filter
      </Typography>
      <div className={classes.buttonContainer}>
        {missionPacks.map((pack, idx)=>{
          return (
            <Button
              key={pack.id}
              classes={{label: classes.packButton}}
              style={{width: '100%', border: '1px solid rgba(0,0,0,0.1)', borderTop: idx !== 0 ? null : '1px solid rgba(0,0,0,0.1)',  borderRadius: 0}}
              className={pack.id === selectedPack?.id ? classes.selectedButton : null}
              onClick={()=>onSelect(pack)}
            >
              <Avatar variant='square' src={lessonContentDomain + AVATAR_SOURCE + pack.avatar} style={{height: '2em', width: '2em'}} />
              <Typography
                variant='body2'
                style={{paddingLeft: '1em'}}
              >
                {pack.title}
              </Typography>
            </Button>
          )
        })}
      </div>
    </div>
  )
}