import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { OutlinedSelect } from '../../../common/fields'
import { CircularProgress, Typography, MenuItem, Accordion, AccordionDetails, AccordionSummary  } from '@material-ui/core'
import { useLogin } from '../../../common/LoginContext'
import { getSim } from '../../../common/utils'
import { LMS3LegAuthorizationPanel } from './LMS3LegAuthorizationPanel'

const useStyles = makeStyles(theme => ({
  accordionSummaryContent: {
    margin: '0px !important',
  },
}))

const getClasses = async (user, lmsId) => {
  try {
    const resp = await getSim(`lms/${lmsId}/classes`, user)
    const data = await resp.json()
    if (resp.status === 200) {
      return { status: resp.status, data }
    }

    return { status: resp.status, data: null, error: data?.message }
  } catch (err) {
    //
  }
}

function FetchingClassesPanel() {
  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height:'100%'}}>
      {'Fetching Google Classes'}
      <div style={{height: 40}}></div>
      <CircularProgress style={{color: '#4CAF5066'}} size={55}/>
      <div style={{height: 40}}></div>
    </div>
  )
}

function ClassSelectionPanel({ selectedGoogleClass, setSelectedGoogleClass, googleClasses=[] }) {
  if (googleClasses?.length === 0) {
    return (
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <Typography>
          No Active Google Classes Found!
        </Typography>
      </div>
    )
  }
  return (
    <div style={{width: '100%'}}>
      <OutlinedSelect
        value={selectedGoogleClass}
        onChange={ev=>setSelectedGoogleClass(ev.target.value)}
        label='Google Class'
        style={{width: '100%', backgroundColor: 'white'}}
        // className={classes.wideField}
      >
        {googleClasses.map(googleClass => <MenuItem key={googleClass.id} value={googleClass.id}>{googleClass.name}</MenuItem>)}
      </OutlinedSelect>
    </div>
  )
}

function LMSIntegrationSelectionPanel({ lmsId, lmsClassId, lmsSelected, setLMSId, onFieldsChange, disabled  }) {
  const [loginState] = useLogin()
  const classes = useStyles()
  const [googleClasses, setGoogleClasses] = React.useState([])
  const [authorizationRequired, setAuthorizationRequired] = React.useState(false)
  const [fetchingClasses, setFetchingClasses] = React.useState(false)
  const CLASS_DROPDOWN_VISIBLE = !authorizationRequired && !fetchingClasses

  const handleGettingClasses = async () => {
    setFetchingClasses(true)
    const resp = await getClasses(loginState?.user, lmsId)
    setFetchingClasses(false)
    if (!resp) {
      return
    }

    const { data, status, error } = resp
    if (status === 401 && error?.type === 'LMS') {
      setAuthorizationRequired(true)
      return
    }

    setGoogleClasses(data)
    setAuthorizationRequired(false)
  }

  const setLMSClass = (lmsClassId) => {
    const lmsClass = googleClasses.find(lmsClass => lmsClass.id === lmsClassId)
    onFieldsChange([['lmsClassId', lmsClassId], ['lmsClassName', lmsClass.name]])
  }

  React.useEffect(() => {
    if (lmsSelected) {
      handleGettingClasses()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lmsId])

  React.useEffect(() => {
    if (googleClasses?.length > 0) {
      setLMSClass(googleClasses[0].id)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleClasses])

  return (
    <div >
      <Accordion expanded={lmsSelected} style={{padding: 0, boxShadow: 'none'}}>
        <AccordionSummary
          style={{padding: 0, minHeight: 0}}
          classes={{
            content: classes.accordionSummaryContent,
          }}
          aria-controls='panel1bh-content'
          id='panel1bh-header'
        >
          <OutlinedSelect
            style={{width: '100%', zIndex: 2}}
            value={lmsId}
            onChange={setLMSId}
            label='LMS'
            disabled={disabled}
          >
            {[{title: 'None', val: 'none'}, {title: 'Google Classroom', val: 'google'}].map(lms => (
              <MenuItem
                key={lms.val}
                divider
                value={lms.val}
              >
                <Typography style={{color: 'black'}}>
                  {lms.title}
                </Typography>
              </MenuItem>
            ))}
          </OutlinedSelect>
        </AccordionSummary>
        <AccordionDetails style={{
          border: '1px solid rgba(0,0,0,0.25)',
          borderTop: null,
          borderBottomLeftRadius: 3,
          borderBottomRightRadius: 3,
          marginTop: -2,
          display: 'flex',
          justifyContent: 'center',
          padding: '20px 10px',
          height: CLASS_DROPDOWN_VISIBLE ? null : 200,
          width: CLASS_DROPDOWN_VISIBLE  ? null : 560,
          backgroundColor: 'rgba(0,0,0,0.01)',
          zIndex: 1,
        }}>
          <LMS3LegAuthorizationPanel
            authorizationRequired={authorizationRequired}
            setAuthorizationRequired={setAuthorizationRequired}
            authorizationSuccessCb={handleGettingClasses}
            lmsId={lmsId}
          />
          {fetchingClasses ?
            <FetchingClassesPanel />:
            null}
          {CLASS_DROPDOWN_VISIBLE ?
            <ClassSelectionPanel
              googleClasses={googleClasses}
              selectedGoogleClass={lmsClassId}
              setSelectedGoogleClass={setLMSClass}
            />:
            null}
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export { LMSIntegrationSelectionPanel }
