import React from 'react'
import { emphasize, makeStyles, withStyles } from '@material-ui/core/styles'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import HomeIcon from '@material-ui/icons/Home'
import PersonIcon from '@material-ui/icons/Person'
import GroupIcon from '@material-ui/icons/Group'
import LockIcon from '@material-ui/icons/Lock'
import { Breadcrumbs, Typography, IconButton, Chip, Tooltip } from '@material-ui/core'

import { adjustColor } from '../../common/utils'

import { useNavigate } from 'react-router-dom'
import { useMatches } from 'react-router-dom'
import { useMembers } from '../../contexts/MembersContext'
import { useGroups } from '../../contexts/GroupContext'
import ActionPanel from './ActionPanel'
import { rightDrawerWidth  } from '../../routes/ClassDashboardSiteHeader'


const useStyles = makeStyles(theme => ({
  appBody: {
    height: 'calc(100vh - 56px)',
  },
  bodyHeader: {
    height: 48,
    border: `1px solid ${theme.palette.custom.quaternary}`,
    borderBottom: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  breadCrumbs: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableNoShift: {
    transition: theme.transitions.create('max-width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    maxWidth: '100vw',
    height: '100%',
  },
  tableShifted: {
    transition: theme.transitions.create('max-width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    maxWidth: '100vw',
    height: '100%',
  },
  mpContent: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
    height: '100%',
  },
  mpShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: rightDrawerWidth,
    height: '100%',
  },
  bodyContent: {
    display:'flex',
    flexGrow: 1,
    flexDirection: 'column',
    height: 'calc(100% - 48px)',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems:'center',
    gap: 10,
  },
  buttonBorder: {
    height:48,
    width: 2,
    borderLeft: `1px solid ${theme.palette.custom.quaternary}`,
  },
  buttonRoot: {
    color: theme.palette.getContrastText('#F4D038'),
    borderRadius: '2px',
    height: '36px',
    backgroundColor: '#F4D038',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: adjustColor('#F4D038', -20),
    },
  },
  headerStyle: {
    display:'flex',
    paddingRight: '10px',
    gap:5,
  },
}))

const HeaderIcon = (props) => {
  return (
    <Tooltip
      title={props.title}
      placement='top'
      arrow
    >
      <IconButton
        onClick={props.onClick}
        style={{height: 44, width: 44, borderRadius: 8, color: 'black',
          backgroundColor: props.selected ? 'rgba(0, 0, 0, 0.15)' : null,
          border: props.selected ? '1px solid rgba(0, 0, 0, 0.25)':null,
        }}
      >
        {props.children}
      </IconButton>
    </Tooltip>
  )
}

const StyledBreadcrumb = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[300],
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
    textTransform: 'none',
  },
}))(Chip)

function formatCrumb(path, params, addCrumb, navigate, selectedGroup, selectedMember) {
  var newPath = path
  if (path.length === 0) {
    return
  }

  const handle = path[0]
  if (handle === 'group') {
    addCrumb([selectedGroup.name, <GroupIcon/>, () => navigate(`/group/${params.groupId}`)])
    newPath = path.slice(2)
  } else if (handle === 'member') {
    addCrumb([selectedMember.formattedName, <PersonIcon/>, () => navigate(`/group/${params.groupId}/member/${params.memberId}`)])
    newPath = path.slice(2)
  } else if (handle === 'lesson-access-control') {
    addCrumb(['Lesson Access Manager', <LockIcon style={{fontSize: 18}}/>, () => {}])
    newPath = path.slice(1)
  }

  formatCrumb(newPath, params, addCrumb, navigate, selectedGroup, selectedMember)
}

function formatCrumbs(matches, navigate, selectedGroup, selectedMember) {
  const crumbs = []
  function addCrumb(crumb) {
    crumbs.push(crumb)
  }

  addCrumb(['My Classes', <HomeIcon/>, () => navigate('/')])
  if (matches.length === 0 || matches.length === 1) {
    return crumbs
  }

  const match = matches[matches.length - 1]
  formatCrumb(
    match.pathname.split('/').filter(path => path !== ''),
    match.params,
    addCrumb,
    navigate,
    selectedGroup,
    selectedMember
  )

  return crumbs
}


export default function PanelTemplate({ headerButtons=[], ...props}) {
  const classes = useStyles()
  const { selectedMember } = useMembers()
  const { selectedGroup } = useGroups()
  const navigate = useNavigate()
  const matches = useMatches()
  const crumbs = formatCrumbs(matches, navigate, selectedGroup, selectedMember)


  function handleBack() {
    navigate(-1)
  }

  const headerBackgroundColor = props.deleteMode ? '#f44336' : '#4CAF50'

  return (
    <div className={classes.appBody}>
      <div className={props.actionPanelOpen ? classes.mpShift : classes.mpContent}>
        <div className={props.actionPanelOpen ? classes.tableShifted : classes.tableNoShift}>
          <div style={{display:'flex', width:'100%', height:'100%'}}>
            {
              props.leftPanelContent ? props.leftPanelContent : null
            }
            <div style={{width:'100%', display:'flex', flexDirection:'column'}}>
              <div className={classes.bodyHeader} style={{backgroundColor: headerBackgroundColor}}>
                <div style={{display:'flex', alignItems: 'center', justifyContent: 'flex-start', width: 164}}>
                  {crumbs.length > 0 ?
                    <IconButton
                      onClick={handleBack}
                      style={{color: 'black', height: '36px', borderRadius: '2px'}}
                    >
                      <div style={{display: 'flex', alignItems:'center', gap: 5}}>
                        <KeyboardBackspaceIcon />
                        <Typography variant='body2'>
                          {'BACK'}
                        </Typography>

                      </div>
                    </IconButton>:
                    null
                  }
                </div>
                <div className={classes.breadCrumbs}>
                  <Breadcrumbs separator='›'>
                    {crumbs.map(([label, icon, onClick], index) =>
                      <StyledBreadcrumb
                        classes={{root: classes.buttonRoot}}
                        onClick={onClick}
                        key={index}
                        label={label}
                        icon={icon}
                      />
                    )}
                  </Breadcrumbs>
                </div>
                {headerButtons.length !== 0 ?
                  <div className={classes.buttonContainer}>
                    <div className={classes.buttonBorder}></div>
                    <div className={classes.headerStyle}>
                      {headerButtons.map((buttonData, index) => {
                        return (
                          <HeaderIcon
                            key={index}
                            title={buttonData.title}
                            selected={buttonData.selected}
                            onClick={buttonData.onClick}
                          >
                            {buttonData.icon}
                          </HeaderIcon>
                        )
                      })

                      }
                      {props.headerPanelContent}
                    </div>
                  </div>:
                  <div style={{width: 164}}></div>}
              </div>
              <div className={classes.bodyContent}>
                {props.mainPanelContent}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ActionPanel
        title={props.actionPanelTitle}
        open={props.actionPanelOpen}
        handleClose={props.actionPanelHandleClose}
      >
        {props.actionPanelContents}
      </ActionPanel>
    </div>
  )
}
