import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, MenuItem, Typography } from '@material-ui/core'

import { OutlinedTextField, OutlinedSelect } from '../../common/fields'
import { RespButton } from '../../common/buttons'
import GenericDialog from '../../common/GenericDialog'

import { DEFAULT_MEMBER_FIELDS, ROLES } from '../shared-components/sharedObjects'


const useStyles = makeStyles(theme => ({
  dialogContent: {
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    gap: 20,
    padding: '5px',
    paddingTop: '25px',
  },
  dialogRoot: {
    maxheight:'90vh',
  },
  dialogContentContainer: {
    width:400,
    display:'flex',
    flexDirection: 'column',
    padding: '10px 0px',
    gap: 15,
  },
}))

function AddMemberDialog(props) {
  const classes = useStyles()
  const [memberFields, setMemberFields] = React.useState(DEFAULT_MEMBER_FIELDS())
  const [fetchStatus, setFetchStatus] = React.useState(null)

  const onFieldChange = (key, value) => {
    var copyOfFields = {...memberFields}
    copyOfFields[key] = value
    setMemberFields(copyOfFields)
  }

  const successCb = () => {
    setMemberFields(DEFAULT_MEMBER_FIELDS())
    props.handleAddMemberSuccess()
  }

  return (
    <GenericDialog
      open={props.open}
      onClose={props.handleClose}
      classes={{paper: classes.dialogRoot}}
      keepMounted
      title={
        <Typography variant='subtitle1'>
            New Member
        </Typography>
      }
      buttons={
        <>
          <RespButton
            variant='contained'
            color='secondary'
            buttonComponent={Button}
            onClick={()=>props.handleAddMember({email: memberFields.email, name: memberFields.name})}
            successCb={successCb}
            status={fetchStatus}
            setStatus={setFetchStatus}
            disabled={memberFields.email === ''}
            resetStatus={!props.open}
          >
            Add Member
          </RespButton>
          <Button
            onClick={props.handleClose}
            variant='outlined'
          >
            Close
          </Button>
        </>
      }
    >
      <div className={classes.dialogContentContainer}>
        <OutlinedTextField
          style={{width: '100%'}}
          label={'Member Email'}
          onChange={ev=>onFieldChange('email', ev.target.value)}
          value={memberFields.email}
        />
        <OutlinedSelect
          value={memberFields.role}
          onChange={ev=>onFieldChange('role', ev.target.value)}
          label='Role'
          style={{width: '100%'}}
        >
          {ROLES.map(role => (
            <MenuItem
              disabled={role === 'Assistant' || role === 'Owner'}
              key={role}
              value={role}>
              {role}
            </MenuItem>
          ))}
        </OutlinedSelect>
      </div>
    </GenericDialog>
  )
}

export { AddMemberDialog }