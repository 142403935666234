import React from 'react'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { red } from '@material-ui/core/colors'
import { RespComponent } from './RespComponent'

const DeleteButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
}))(Button)

const FiriaGreenButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText('#4CAF50'),
    backgroundColor: '#4CAF50',
    '&:hover': {
      backgroundColor: '#5CD561',
    },
  },
}))(Button)


const RespButton = (props) => {
  const parseProps = () => {
    const {
      buttonComponent,
      ...formattedProps
    } = props
    return formattedProps
  }

  return (
    <RespComponent
      childComponent={props.buttonComponent}
      type='onClick'
      {...parseProps()}
    >
      {props.children}
    </RespComponent>
  )
}

export { DeleteButton, FiriaGreenButton, RespButton }