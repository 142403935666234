
const CURATED_ASSIGNMENTS_BY_MISSION_PACK_ID = {
  CTE: [
    {missionId: 'Free_03LightTheWay', flowIds: ['Free_03LightTheWay_03o','Free_03LightTheWay_05o']},
    {missionId: 'Free_04GetMoving', flowIds: ['Free_04GetMoving_03o']},
    {missionId: 'CTE_05DanceBot', flowIds: ['CTE_05DanceBot_04o', 'CTE_05DanceBot_07o']},
    {missionId: 'CTE_01Metronome', flowIds: ['CTE_01Metronome_04o', 'CTE_01Metronome_08o', 'CTE_01Metronome_10o']},
    {missionId: 'CTE_02LineSensors', flowIds: ['CTE02LineSensors_03o', 'CTE02LineSensors_06o']},
    {missionId: 'CTE_BoundaryPatrol', flowIds:['CTE_BoundaryPatrol_04o', 'CTE_BoundaryPatrol_06o']},
    {missionId: 'CTE_LineFollowing', flowIds: ['CTE_LineFollowing_04o', 'CTE_LineFollowing_055o', 'CTE_LineFollowing_07o']},
    {missionId: 'CTE_FidoFetch', flowIds:['CTE_FidoFetch_03o', 'CTE_FidoFetch_09o']},
    {missionId: 'CTE_RunwayOps', flowIds: ['CTE_RunwayOps_03o', 'CTE_RunwayOps_06o']},
    {missionId: 'CTE_RockClimber', flowIds: ['CTE_RockClimber_02_1o', 'CTE_RockClimber_05o']},
    {missionId: 'CTE_GoingTheDistance', flowIds: ['CTE_TheDistance_06o', 'CTE_TheDistance_11o']},
    {missionId: 'CTE_MusicBox', flowIds: ['CTE_MusicBox_o05', 'CTE_MusicBox_o10']},
    // {missionId: 'CTE_CyberStorm', flowIds: ['CTE_CyberStorm_05o', 'CTE_CyberStorm_09o']},
  ],
  LCX: [
    {missionId: 'LCX_WelcomeToCodeX', flowIds: ['LCX_WelcomeToCodeX_09o']},
    {missionId: 'LCX_LightShow', flowIds: ['LCX_LightShow_07o', 'LCX_LightShow_09o']},
    {missionId: 'LCX_DisplayGames', flowIds: ['LCX_DisplayGames_06o', 'LCX_DisplayGames_10o']},
    {missionId:  'LCX_MicroMusician', flowIds: ['LCX_MicroMusician_o06']},
    {missionId: 'LCX_Heartbeat', flowIds: ['LCX_Heartbeat_o07', 'LCX_Heartbeat_o11']},
    {missionId: 'LCX_PersonalBillboard', flowIds: ['LCX_PersonalBillboard_o03', 'LCX_PersonalBillboard_o06', 'LCX_PersonalBillboard_o09']},
    {missionId: 'LCX_AnswerBot', flowIds: ['LCX_AnswerBot_o04', 'LCX_AnswerBot_o07']},
    {missionId: 'LCX_GameSpinner', flowIds: ['LCX_GameSpinner_o04', 'LCX_GameSpinner_o08']},
    {missionId: 'LCX_ReactionTester', flowIds: ['LCX_ReactionTester_o04', 'LCX_ReactionTester_o07']},
    {missionId: 'LCX_SpiritLevel', flowIds: ['LCX_SpiritLevel_o06']},
    {missionId: 'LCX_NightLight', flowIds: ['LCX_NightLight_o05']},
  ],
  PWR: [
    {missionId: 'PWR_Intro', flowIds: ['PWR_Intro_Lights_On']},
    {missionId: 'PWR_TimeMotion', flowIds: ['PWR_TimeMotion_Light_Show', 'PWR_TimeMotion_Bright_Byte_Lights', 'PWR_TimeMotion_Rotation_Time', 'PWR_TimeMotion_Button_it_Up']},
    {missionId: 'PWR_Animatronics', flowIds: ['PWR_Animatronics_Putting_It_All_Together']},
    {missionId: 'PWR_FencePatrol', flowIds: ['PWR_FencePatrol_Fence_Patrol_v10']},
    {missionId: 'PWR_LineFollower', flowIds: ['PWR_LineFollower_Magic_Lights_Redux', 'PWR_LineFollower_Read_the_Line']},
    {missionId: 'PWR_HotPursuit', flowIds: ['PWR_HotPursuit_Chase_Mode_2']},
    {missionId: 'PWR_Navigation', flowIds: ['PWR_Navigation_Dead_Reckoning_2']},
    {missionId: 'PWR_AllSystemsGo', flowIds: ['PWR_AllSystemsGo_Battery_Indicator_Light', 'PWR_AllSystemsGo_Temperature_Controlled_Lights', 'PWR_AllSystemsGo_Upgrade', 'PWR_AllSystemsGo_Guard_Bot_2']},
  ],
  HWN: [
    {missionId: 'H23_FrontPorch', flowIds: ['H23_FrontPorch_SpinMove', 'H23_FrontPorch_ChaseSpiders', 'H23_FrontPorch_RatTone', 'H23_FrontPorch_DoorDash']},
    {missionId: 'H23_LivingRoom', flowIds: ['H23_LivingRoom_Snack', 'H23_LivingRoom_Crossing']},
    {missionId: 'H23_Kitchen', flowIds: ['H23_Kitchen_Hex', 'H23_Kitchen_Lanterns', 'H23_Kitchen_Escape']},
    {missionId: 'H23_Hallway', flowIds: ['H23_Hallway_Trail']},
    {missionId: 'H23_Library', flowIds: ['H23_Library_Book', 'H23_Library_Sequence', 'H23_Library_Ramp']},
    {missionId: 'H23_Attic', flowIds: ['H23_Attic_ChartCourse', 'H23_Attic_Turing', 'H23_Attic_Catapult', 'H23_Attic_Morse', 'H23_Attic_ASCII']},
  ],
  LFT: [
    {missionId: 'LFT_PeripheralsIntro', flowIds: ['LFT_PeripheralsIntro_Meaningful_Code']},
    {missionId: 'LFT_LiftOff', flowIds: ['LFT_LiftOff_LiftOff']},
    {missionId: 'LFT_ConserveEnergy', flowIds: ['LFT_ConserveEnergy_Time_to_Blackout']},
    {missionId: 'LFT_HatchLock', flowIds: ['LFT_HatchLock_Was_the_Button_Pressed']},
    {missionId: 'LFT_AlertSystem', flowIds: ['LFT_AlertSystem_Complete_Alert_System']},
    {missionId: 'LFT_LifeSupport', flowIds: ['LFT_LifeSupport_Software_States']},
    {missionId: 'LFT_SolarTracking', flowIds: ['LFT_SolarTracking_Automatic_Sun_Tracking']},
    {missionId: 'LFT_PrepareLander', flowIds: ['LFT_PrepareLander_Set_up_the_Landing_Gear']},
    {missionId: 'LFT_AutomaticGarden', flowIds: ['LFT_AutomaticGarden_Fine_Tune_Automation']},
    {missionId: 'LFT_ExploringSurface', flowIds: ['LFT_ExploringSurface_Power_Down']},
  ],
}

export { CURATED_ASSIGNMENTS_BY_MISSION_PACK_ID }