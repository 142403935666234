import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Typography } from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings'

import DataTable from '../../common/DataTable'
import { useLogin } from '../../common/LoginContext'
import { deleteSim, adjustColor, lessonContentDomain } from '../../common/utils'
import { useMissionPacks } from '../../common/MissionPackContext'
import GenericDialog from '../../common/GenericDialog'

import PanelTemplate from '../shared-components/PanelTemplate'
import { EditMemberActionPanel } from './EditMemberActionPanel'
import WarningDialog from '../shared-components/WarningDialog'
import { DeleteActionPanel } from '../shared-components/ActionPanel'
import { appHeaderHeight } from '../../routes/ClassDashboardSiteHeader'
import { useGroups } from '../../contexts/GroupContext'
import { useMembers } from '../../contexts/MembersContext'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  bodyContainer: {
    backgroundColor: 'white',
    // border: `1px solid ${theme.palette.custom.quaternary}`,
    height: '100%',
    overflowX: 'auto',
    overflowY: 'hidden',
  },
  flowItem: {
    padding: '5px 2px',
    height: 44,
    border: '1px solid black',
    userSelect: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const columns = (progress, onItemClick)=> {
  return [
    { field: 'mission',
      headerName: 'Mission',
      width: 220,
      renderCell: (params) => {
        return (
          <div style={{display:'flex', alignItems:'center', gap:10}}>
            <img src={lessonContentDomain+'/pub/'+params.row.avatar} width={40} height={40} alt=''/>
            <div>
              {`${params.row.index+1 < 10 ? '0' + (params.row.index+1) : params.row.index+1} ${params.row.title}`}
            </div>
          </div>
        )
      },
    },
    {
      field: 'content',
      headerName: 'Lesson Content',
      flex: 1,
      renderCell: (params) => {
        const mission = params.row
        if (!mission || !progress){
          return null
        }
        const missionProgress = progress[mission.id]
        // const missionsLength = selectedPack?.missions.length-1
        var objectiveCount = 0
        var quizCount = 0
        return (
          <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
            {params.row.flow.map(((flowItem, flowIndex) => {
              const type = ('questions' in mission.flow[flowIndex]) ? 'Quiz' : 'Objective'
              if (type === 'Quiz'){
                quizCount += 1
              } else {
                objectiveCount += 1
              }
              const flowProgress = missionProgress?.flow[flowIndex] ?? null
              let flowItemCompleted
              if (flowProgress){
                flowItemCompleted = flowProgress?.completed ?? false
              }

              return (
                <FlowItem
                  key={`${params.row.id}-${flowIndex}`}
                  flowItemCompleted={flowItemCompleted}
                  type={type}
                  quizCount={quizCount}
                  objectiveCount={objectiveCount}
                  handleClick={() => onItemClick([params.row.id, flowIndex, quizCount])}
                />
              )
            }))}
          </div>
        )
      },
    },
  ]
}

const FlowItem = (props) => {
  const [hovered, setHovered] = React.useState(false)
  const classes = useStyles()
  const quiz = props.type === 'Quiz'
  const selectable =  quiz && props.flowItemCompleted

  const getBackgroundColor = () => {
    let color = '#c9c9c9'
    if (props.flowItemCompleted) {
      color = '#b6fcba'
      if (hovered && quiz){
        color = adjustColor(color, -25)
      }
    }
    return color
  }

  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={selectable ? props.handleClick : () => {}}
      className={classes.flowItem}
      style={{
        borderRadius: quiz ? 3 : 22,
        width: 44, backgroundColor: getBackgroundColor(),
        cursor: selectable ? 'pointer' : null,
      }}
    >
      {`${props.type === 'Quiz' ? 'Q'+props.quizCount : props.objectiveCount}`}
    </div>
  )
}

const QuizDialog = (props) => {
  const handleClose = ()=>props.setSelectedFlowItem(null)
  const flowItem = props.selectedFlowItem
  const [quizData, setQuizData] = React.useState({
    title: null,
    number: null,
    questions: [],
  })

  React.useEffect(() => {
    const tempQuizData = {
      title: null,
      number: null,
      questions: [],
    }
    if (flowItem){
      const selectedMission = props.selectedPack.missions.find(mission => mission.id === flowItem[0])

      const quizProgress = props.progress[flowItem[0]].flow[flowItem[1]]
      const quizContent = selectedMission.flow[flowItem[1]]

      const questions = []
      quizProgress.questions.forEach((question, index) => {
        const quizContentQuestion = quizContent.questions[index]
        const intersection = question.answers.filter(x => !question.selected.includes(x))
        const answers = []

        question.selected.forEach((answerIndex) => {
          answers.push(quizContentQuestion.answers[answerIndex])
        })
        intersection.forEach((answerIndex) => {
          answers.push(quizContentQuestion.answers[answerIndex])
        })
        questions.push({q: quizContentQuestion.q, answers: answers})
      })

      tempQuizData.number = flowItem[2]
      tempQuizData.title = selectedMission?.title
      tempQuizData.questions = questions

      setQuizData(tempQuizData)
    }

    setQuizData(tempQuizData)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowItem])

  return (
    <GenericDialog
      open={Boolean(flowItem)}
      onClose={handleClose}
      title={
        <Typography>
          {`${quizData.title} - Quiz ${quizData.number}`}
        </Typography>
      }
      buttons={
        <Button
          onClick={handleClose}
          variant='outlined'
        >
          Close
        </Button>
      }
    >
      <div>
        {quizData.questions.map((question, index) => {
          return (
            <div>
              <div key={index}>
                { question.q }
              </div>
              <div>
                {question.answers.map(answer => <div>{answer.a}</div>)}
              </div>
            </div>
          )
        })

        }
      </div>
    </GenericDialog>
  )
}

function MemberPage() {
  const classes = useStyles()
  const navigate = useNavigate()
  const {
    groups,
    selectedGroup,
    selectedGroupId,
  } = useGroups()
  const {
    selectedMember,
    getMembers,
    membersFetchStatus,
  } = useMembers()

  const [openDeleteMemberDialog, setOpenDeleteMemberDialog] = React.useState(false)
  const [showActionPanel, setShowActionPanel] = React.useState('settings')
  const [rowsToDelete] = React.useState([])
  const [selectedFlowItem, setSelectedFlowItem] = React.useState(null)
  const [tableParams, setTableParams] = React.useState({
    headerHeight: null,
    pageSize: 0,
  })
  const [loginState] = useLogin()
  const { missionPacks } = useMissionPacks()
  const rowData = selectedGroup

  const selectedPack = missionPacks.find(pack => pack.id === rowData.missionPackId)
  const selectedMemberData = selectedMember

  const handleDeleteMembers = async (memberIds) => {
    return await deleteSim(`groups/${selectedGroupId}/members/${memberIds[0]}`, loginState?.user)
  }

  const handleDeleteMembersSuccess = () => {
    navigate(-1)
    getMembers()
  }
  const openSettingsActionMenu = () => {
    if (showActionPanel === 'settings'){
      setShowActionPanel(false)
      return
    }
    setShowActionPanel('settings')
  }

  return (
    <PanelTemplate
      headerButtons={[
        {
          title: 'Edit Member',
          selected: showActionPanel === 'settings',
          onClick: openSettingsActionMenu,
          icon: <SettingsIcon />,
        },
      ]}
      mainPanelContent={
        <div className={classes.bodyContainer} style={{display:'flex'}}>
          <DataTable
            appHeaderHeight={appHeaderHeight}
            // defaultSort={{field: 'email', sort: 'asc'}}
            autoPageSize
            status={'success'}
            tableContent='members'
            addItemComment='Click the + button above to add a new member!'
            style={{borderRadius: 0}}
            rows={selectedPack?.missions.map((mission, index)=>{
              mission.index = index
              return mission
            }) ?? []}
            columns={columns(selectedMemberData?.progress, setSelectedFlowItem)}
            isVisible={true}
            disableColumnMenu
            tableParams={tableParams}
            setTableParams={setTableParams}
          />
          <WarningDialog
            title={'Delete Members'}
            content={'this member'}
            open={openDeleteMemberDialog}
            handleClose={()=>setOpenDeleteMemberDialog(false)}
            handleDelete={() => handleDeleteMembers([selectedMemberData?.id])}
            handleDeleteSuccess={handleDeleteMembersSuccess}
            rowsSelected={rowsToDelete.length}
            selectedFlowItem={selectedFlowItem}
          />
          {false ?
            <QuizDialog
              selectedFlowItem={selectedFlowItem}
              setSelectedFlowItem={setSelectedFlowItem}
              progress={selectedMemberData?.progress}
              selectedMemberData={selectedMemberData}
              selectedPack={selectedPack}
            />:
            null}
        </div>
      }
      actionPanelOpen={showActionPanel !== false}
      actionPanelContents={
        <div>
          {showActionPanel === 'delete' ?
            <DeleteActionPanel
              title={'Select members to delete'}
              waiting={membersFetchStatus === 'waiting'}
              handleClose={() => setShowActionPanel(false)}
              handleDelete={() => setOpenDeleteMemberDialog(true)}
              handleDeleteSuccess={handleDeleteMembersSuccess}
              rowsSelected={rowsToDelete.length}
              type={'Member(s)'}
            />:
            showActionPanel === 'settings' ?
              <EditMemberActionPanel
                handleClose={() => setShowActionPanel(false)}
                groupId={selectedGroupId}
                groupData={selectedGroup}
                groups={groups}
                title={'Edit Student'}
                classes={classes}
                memberData={selectedMemberData}
                editGroupMemberSuccess={getMembers}
                deleteMember={() => setOpenDeleteMemberDialog(true)}
                deleteMemberSuccess={handleDeleteMembersSuccess}
              />:
              null}
        </div>
      }
    />
  )
}

export { MemberPage }