import React from 'react'
import FiriaLabsBanner from './firialabsbanner.png'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

function LoadingErrorPage(props) {
  return (
    <Paper
      style={{
        width: '50%',
        minWidth: '300px',
        padding: '50px',
        margin: 'auto',
        marginTop: '50px',
      }}
    >
      <div style={{ marginTop: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
        <img alt={''}  style={{display: 'flex', alignItems:'center'}} src={FiriaLabsBanner}/>
        <Typography
          align='center'
          variant='h4'
          style={{ marginTop: '40px' }}
        >
            Sorry, we are having some trouble loading...
        </Typography>
        <div>
          <Typography
            align='center'
            variant='body1'
            style={{ marginTop: '30px' }}
          >
            We received a 404 error from one of our requests.
          </Typography>
        </div>
        <div style={{ width: '100%', textAlign: 'center', marginTop: '50px' }}>
          <Typography
            align='center'
            variant='body1'
            style={{ marginBottom: '5px' }}
          >
            This may be an issue with your network or firewall settings.
          </Typography>
          <p>
            See <a href='https://firialabs.com/blogs/support-forum/whitelist-urls'>Required URLs</a> for details.
          </p>
          <p>
            Feel free to contact our support team for help!
          </p>
          <a href='mailto: support@firialabs.com'>support@firialabs.com</a>
        </div>
      </div>
    </Paper>
  )
}

export default LoadingErrorPage
