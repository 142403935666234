import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FiriaLabsBanner from './firialabsbanner.png'
import { Typography } from '@material-ui/core'
import { FiriaGreenButton } from './buttons'
import { redirectToLoginSite } from './LoginContext'
import { USER_LOGGED_IN_ON_MULTIPLE_DEVICES_ERROR_CODE, FAILED_TO_AUTHENTICATE_USER } from './user-session-error-codes'
import { useLogin } from './LoginContext'

const useStyles = makeStyles(theme => ({
  logoutPageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  firiaBanner: {
    display: 'flex',
    alignItems:'center',
  },
  sessionContainer: {
    width: 800,
    padding: '30px 60px',
    borderRadius: 10,
    backgroundColor: 'rgba(0,0,0,0.025)',
    border: '1px solid rgba(0,0,0,0.15)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  topHalfContainer: {
    display: 'flex',
    alignItems:'center',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'column',
    padding: 15,
    paddingBottom: 20,
  },
  dividerContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  divider: {
    flex: 1,
    marginLeft: 5,
    marginRight: 5,
  },
  bottomButtonContainer: {
    display: 'flex',
    alignItems:'center',
    justifyContent: 'center',
    padding: 15,
    paddingTop: 20,
    flexDirection: 'column',
  },
}))

function LoginButton(props) {
  return (
    <FiriaGreenButton onClick={redirectToLoginSite} variant='contained' style={{ width: 260 }} {...props} >
      {'Log In'}
    </FiriaGreenButton>
  )
}

function SessionEndingErrorContainer({ children }) {
  const classes = useStyles()

  const BUTTON_WIDTH = 260
  return (
    <div className={classes.logoutPageContainer}>
      <img alt={''} className={classes.firiaBanner} src={FiriaLabsBanner}/>
      <div className={classes.sessionContainer} >
        {children}
        <LoginButton style={{ width: BUTTON_WIDTH }} paperProps={{ elevation: 0 }}/>
      </div>
    </div>
  )
}

function UserSessionOverwrittenPage() {
  const [loginState] = useLogin()

  return (
    <SessionEndingErrorContainer>
      {
        loginState?.displayUserLoggedOutMessage === USER_LOGGED_IN_ON_MULTIPLE_DEVICES_ERROR_CODE ?
          <>
            <Typography variant={'h5'}>{'Your account has been logged into on another device.'}</Typography>
            <Typography style={{paddingTop: 10, paddingBottom: 20}}>{'You\'ve been logged out on this device!'}</Typography>
          </>: loginState?.displayUserLoggedOutMessage === FAILED_TO_AUTHENTICATE_USER ?
            <>
              <Typography variant={'h5'}>{'Your session has expired.'}</Typography>
              <Typography style={{paddingTop: 10, paddingBottom: 20}}>{'Please log back in to start a new session!'}</Typography>
            </>:
            null
      }
    </SessionEndingErrorContainer>
  )
}

export { UserSessionOverwrittenPage }