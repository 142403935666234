import React from 'react'
import ReactDOM from 'react-dom'
import {
  RouterProvider,
} from 'react-router-dom'
import './index.css'
import { SnackbarProvider } from 'notistack'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'

import { LoginProvider } from './common/LoginContext'
import { MissionPackProvider } from './common/MissionPackContext'
import { GroupsProvider } from './contexts/GroupContext'
import { MembersProvider } from './contexts/MembersContext'

import { router } from './routes/router'


var mode = 'light'
const darkModeColors = {
  primary: '#5E5E5E',
  secondary: '#4CAF50',
  tertiary: '#ffffff',
  quaternary: '#444444',
  accent: '#4CAF50',
  quinary: '#515151',
  background: '#2B2B2B',
  outlinedTextField: {
    disabled: {
      label: '#9B9B9B',
    },
    unfocused: {
      label: '#FFFFFF',
      border: '#FFFFFF',
    },
    focused: {
      label: '#FFFFFF',
      border: '#FFFFFF',
    },
    hovered: {
      label: 'black',
      border: 'black',
    },
  },
}

const lightModeColors = {
  primary: '#FFFFFF',
  secondary: '#4CAF50',
  tertiary: '#ffffff',
  quaternary: '#444444',
  accent: '#4CAF50',
  quinary: '#4CAF50',
  background: '#2B2B2B',
  outlinedTextField: {
    disabled: {
      label: '#9B9B9B',
    },
    unfocused: {
      label: 'black',
      border: 'black',
    },
    focused: {
      label: 'black',
      border: 'black',
    },
    hovered: {
      label: 'black',
      border: 'black',
    },
  },
}

var colors = mode === 'dark' ? darkModeColors : lightModeColors

const theme = createTheme({
  palette: {
    type: mode,
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.accent,
    },
    custom: colors,
    // text:{
    //   primary: '#a5a58d',
    // },
  },
})

ReactDOM.render(
  <React.StrictMode>
    <SnackbarProvider>
      <MissionPackProvider missionContent>
        <LoginProvider>
          <GroupsProvider>
            <MembersProvider>
              <ThemeProvider theme={theme}>
                <RouterProvider router={router} />
              </ThemeProvider>
            </MembersProvider>
          </GroupsProvider>
        </LoginProvider>
      </MissionPackProvider>
    </SnackbarProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
