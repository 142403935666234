import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'

import { DataGrid } from '@material-ui/data-grid'

const cellHeight = 52
const tableHeaderHeight = 52
const tableFooterHeight = 52

const useStyles = (checkColor, appHeaderHeight) => makeStyles(theme => ({
  overlayContainer: {
    height: '100%',
    // paddingBottom: 100,
    marginTop: appHeaderHeight,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    color: 'black',
  },
  fetchingOverlay: {
    color: theme.palette.custom.accent,
  },
  root: {
    overflowY: 'visible !important',
    borderColor: `${theme.palette.custom.quaternary} !important`,
    '&.MuiDataGrid-root .MuiDataGrid-columnsContainer': {
      borderColor: `${theme.palette.custom.quaternary} !important`,
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnSeparator': {
      color: `${theme.palette.custom.quaternary} !important`,
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-row.Mui-odd:not(:hover)': {
      backgroundColor: '#f5f5f5',
      color: 'black',
    },
    '& .MuiDataGrid-row.Mui-even:not(:hover)': {
      backgroundColor: 'white',
      color: 'black',
    },
    '& .MuiDataGrid-columnHeader': {
      backgroundColor: '#c9c9c9',
      color: 'black',
      fontSize: '0.8em',
    },
    '& .MuiDataGrid-columnsContainer': {
      backgroundColor: '#c9c9c9',
    },
    '& .MuiDataGrid-footerContainer': {
      backgroundColor: '#c9c9c9',
      color: 'black',
    },
    '& .MuiTablePagination-root': {
      color: 'black',
    },
    '& .MuiDataGrid-checkboxInput': {
      color: `${checkColor ?? theme.palette.custom.accent} !important`,
    },
    '& .MuiDataGrid-viewport': {
      overflowY: 'visible',
    },
    '& .MuiDataGrid-window': {
      overflowY: 'visible !important',
    },
    '& .MuiCheckbox-colorPrimary.Mui-disabled': {
      color: `${checkColor ?? theme.palette.custom.accent}42 !important`,
    },
    borderRadius: 0,
  },
  row: {
    cursor: 'pointer',
  },
}))

export default function DataTable(props) {
  const classes = useStyles(props.checkColor, props.appHeaderHeight)()
  const [sortModel, setSortModel] = React.useState([])
  const gridRef = React.useRef(null)
  const resizeTimeout = React.useRef(null)
  const tableParams = props.tableParams

  const parseProps = () => {
    const {
      columnWidth,
      rows,
      status,
      tableType,
      rowHeight,
      headerHeight,
      pageSize,
      tableParams,
      ...formattedProps } = props
    return formattedProps
  }

  const FetchingOverlay = () => {
    return (
      <div className={classes.overlayContainer}>
        <CircularProgress className={classes.fetchingOverlay} />
      </div>
    )
  }

  const FailedOverlay = () => {
    return (
      <div className={classes.overlayContainer}>
        <Typography variant={'subtitle1'}>
          {'Unexpected error! Please reload.'}
        </Typography>
      </div>
    )
  }

  const SucceededOverlay = () => {
    return (
      <div className={classes.overlayContainer}>
        <div>
          <Typography variant={'subtitle1'}>
            {`You don't have any ${props.tableContent}!`}
          </Typography>
          <br />
          <Typography variant={'subtitle1'}>
            {`${props.addItemComment}`}
          </Typography>
        </div>
      </div>
    )
  }

  const LoggedOutOverlay = () => {
    return (
      <div className={classes.overlayContainer}>
        <Typography variant={'subtitle1'}>
          {`Log in to view your ${props.tableContent}!`}
        </Typography>
      </div>
    )
  }


  var noRowsOverlay = null
  switch (props.status) {
    case 'waiting':
      noRowsOverlay = FetchingOverlay
      break
    case 'error':
      noRowsOverlay = FailedOverlay
      break
    case 'success':
      noRowsOverlay = SucceededOverlay
      break
    case 'loggedOut':
      noRowsOverlay = LoggedOutOverlay
      break
    default:
      break
  }

  const rows = props.rows
  const defaultSort = props.defaultSort
  React.useEffect(() => {
    if (sortModel.length === 0 && defaultSort) {
      setSortModel([defaultSort])
    }
  }, [rows, defaultSort, sortModel])

  React.useEffect(() => {
    const handlePageResize = () => {
      const calcHeaderHeight = () => {
        const updateParams = (headerHeight, pageSize) => {
          if (tableParams.headerHeight !== headerHeight || tableParams.pageSize !== pageSize) {
            props.setTableParams({
              headerHeight: headerHeight,
              pageSize: pageSize,
            })
          }
        }

        if (!gridRef.current) {
          updateParams(tableHeaderHeight, rows.length)
          props.setTableParams({
            headerHeight: tableHeaderHeight,
            pageSize: rows.length,
          })
          return
        }

        const gridRect = gridRef.current.getBoundingClientRect()
        const height = gridRect.height
        const containerHeight = height - tableFooterHeight - tableHeaderHeight
        const rowsVisible = Math.floor(containerHeight / cellHeight)

        const adjustedHeaderHeight = containerHeight - (rowsVisible * cellHeight) + tableHeaderHeight - 2
        updateParams(adjustedHeaderHeight, rowsVisible)
      }

      clearTimeout(resizeTimeout.current)
      resizeTimeout.current = setTimeout(() => {
        calcHeaderHeight()
      }, 100)
    }

    handlePageResize()

    window.addEventListener('resize', handlePageResize)
    return () => {
      window.removeEventListener('resize', handlePageResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, tableParams])

  React.useEffect(() => {
    return () => {
      clearTimeout(resizeTimeout.current)
    }
  }, [])

  return (
    <DataGrid
      ref={gridRef}
      sortModel={sortModel}
      onSortModelChange={setSortModel}
      rows={props.status === 'waiting' || props.status === 'error' ? [] : props.rows}
      classes={{ root: classes.root, cell: classes.root, columnsContainer: classes.root, row: props.onRowClick ? classes.row : null }}
      components={{
        NoRowsOverlay: noRowsOverlay,
      }}
      headerHeight={props.tableParams.headerHeight ?? tableHeaderHeight}
      pageSize={props.tableParams.pageSize}
      rowHeight={cellHeight}
      scrollbarSize={0}
      {...parseProps()}
    />
  )
}
