import React from 'react'

import { getSim } from '../common/utils'
import { useLogin } from '../common/LoginContext'
import { useMissionPacks } from '../common/MissionPackContext'

import { useGroups } from './GroupContext'



const formatProgressData = (members, selectedMissionPack) => {
  members.forEach((member) => {
    member.formattedName = member.name ? member.name : member.email

    let xp = 0
    let totalFlow = 0
    let flowCompleted = 0
    let lastCompletedMission = 0
    let lastCompletedFlow = 0
    // group progress is the mission progress exclusivly from the missison pack the group has selected
    member.groupProgress = []
    for (const index in selectedMissionPack.missions){
      const missionId = selectedMissionPack.missions[index].id
      totalFlow += selectedMissionPack.missions[index].flow.length
      // const missionProgress = member.progress.find(mission => mission.id === selectedMissionPack.missions[index].id)
      if (missionId in member.progress){
        const missionProgress = member.progress[missionId]
        for (const flowIndex in missionProgress.flow){
          const flowProgress = missionProgress.flow[flowIndex]
          // A student could have gotten xp for a correct question without finishing the entire quiz.
          if (flowProgress.type === 'quiz'){
            for (const questionIndex in flowProgress.questions){
              const question = flowProgress.questions[questionIndex]
              if ('xp' in question){
                xp += parseInt(question.xp)
              }
            }
          }
          if (flowProgress.completed !== ''){
            if (flowProgress.type === 'objective'){
              xp += parseInt(flowProgress.xp)
            }
            flowCompleted += 1
            lastCompletedMission = parseInt(index)
            lastCompletedFlow = parseInt(flowIndex)
          }
        }
      }
    }
    member.totalXp = xp ?? 0
    member.overallPercent = Math.round(flowCompleted/totalFlow*100)
    member.role = member.role ? member.role.charAt(0).toUpperCase() + member.role.slice(1) : ''

    member.completedUpTo = 'Finished!'

    if (member.overallPercent === 0){
      member.completedUpTo = 'Mission 1, Objective 1'
    }

    if (member.overallPercent !== 100 && member.overallPercent !== 0){
      let missionLapped
      let flowValue
      lastCompletedFlow+=1
      // If the last completed flow finished the mission, iterate to the next one, if that mission is outside the range of
      // the mission pack, the user is done!
      if (selectedMissionPack.missions[lastCompletedMission]?.flow.length === lastCompletedFlow){
        lastCompletedMission += 1
        lastCompletedFlow = 0
        flowValue = 1
        missionLapped = true
      }

      const getType = (missionIndex, flowIndex) => {
        return selectedMissionPack?.missions[missionIndex]?.flow[flowIndex] &&
        ('questions' in selectedMissionPack?.missions[missionIndex]?.flow[flowIndex]) ? 'Quiz' : 'Objective'
      }
      const flowType = getType(lastCompletedMission, lastCompletedFlow)

      if (!missionLapped){
        let flowPosition = 0
        for (var i=0; i<lastCompletedFlow+1; i++){
          if (getType(lastCompletedMission, i) === flowType){
            flowPosition += 1
          }
        }
        flowValue = flowPosition
      }
      member.completedUpTo = `Mission ${lastCompletedMission + 1}, ${flowType} ${flowValue}`
    }
  })
  return members
}

const MembersStateContext = React.createContext()
function MembersProvider({ children }) {
  const [loginState] = useLogin()
  const { missionPacks } = useMissionPacks()
  const { selectedGroupId, selectedGroup } = useGroups()
  const [members, setMembers] = React.useState([])
  const [selectedMemberId, setSelectedMemberId] = React.useState(null)
  const [membersFetchStatus, setMembersFetchStatus] = React.useState('loggedOut')

  async function getMembers() {
    if (!selectedGroupId || membersFetchStatus === 'waiting') {
      return
    }

    setMembersFetchStatus('waiting')
    const resp = await getSim(`groups/${selectedGroupId}/members`, loginState?.user)
    if (resp.status === 200){
      const data = (await resp.json())
      data.forEach((row, index) => {
        row.id = row.userId
      })

      setMembers(formatProgressData(data, missionPacks.find(pack => pack.id === selectedGroup.missionPackId)))
      setMembersFetchStatus('success')
    } else if (resp.status === 422){
      setMembersFetchStatus('error')
    }
  }

  function updateMembers(memberUpdateArr) {
    setMembers((z) => {
      const x = [...z]
      for (const [memberId, newMemberData] of memberUpdateArr) {
        const idx = x.findIndex(member => member.id === memberId)
        if (idx === -1) {
          continue
        }

        x[idx] = { ...x[idx], ...newMemberData }
      }
      return x
    })
  }

  function updateMember(memberId, newMemberData) {
    updateMembers([[memberId, newMemberData]])
  }

  React.useEffect(() => {
    if (!selectedGroupId) {
      return
    }
    getMembers()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroupId])

  return (
    <MembersStateContext.Provider
      value={{
        members,
        selectedMemberId,
        selectedMember: members.find(member => member.id === selectedMemberId) ?? {},
        membersFetchStatus,
        setSelectedMemberId,
        getMembers,
        updateMember,
        updateMembers,
      }}>
      {children}
    </MembersStateContext.Provider>
  )
}

function useMembers() {
  return React.useContext(MembersStateContext)
}

export {
  MembersProvider,
  useMembers,
}
