import React from 'react'
import pollSessionIdCookie from './tools/auth/pollSessionIdCookie'
import handleLogout from './tools/auth/handleLogout'
import { setUserAuthCallbacks, hasCookieSessionIdInitializationCompleted, hasUserSessionInfoInitializationCompleted } from './tools/auth/userAuthStore'
import { subscribeToAuthFetchResponses } from './tools/auth/authFetchHandler'
import { USER_LOGGED_IN_ON_MULTIPLE_DEVICES_ERROR_CODE, FAILED_TO_AUTHENTICATE_USER } from './user-session-error-codes'
pollSessionIdCookie()

export const LoginActions = Object.freeze({
  // e.g. Login, Logout
  SIGNUP: Symbol('sign up'),
  EMAIL_LOGIN: Symbol('email login'),
  GOOGLE_LOGIN: Symbol('google login'),
  FORGOT_PASSWORD: Symbol('forgot password'),
  LOGOUT: Symbol('logout'),
})

var loginSite

if (process.env.NODE_ENV === 'development') {
  loginSite = '//localhost:3006'
} else if (window.location.host.includes('dev')) {
  loginSite = '//dev-login.firialabs.com'
} else {
  loginSite = '//login.firialabs.com'
}

export const redirectToLoginSite = () => {
  window.location.replace(loginSite + '?redirect=y&returnTo=' + window.location.host)
}

const LoginStateContext = React.createContext()
const LoginDispatchContext = React.createContext()
export const LoginProvider = ({ children }) => {
  const [user, setUser] = React.useState(hasUserSessionInfoInitializationCompleted())
  const [loginReady, setLoginReady] = React.useState(hasCookieSessionIdInitializationCompleted())

  setUserAuthCallbacks(
    (user) => {
      setUser(user)
    },
    () => {
      setLoginReady(true)
    }
  )

  const skipRedirect = React.useRef(false)
  React.useEffect(() => {
    if (!user && loginReady && !skipRedirect.current) {
      redirectToLoginSite()
    }
  }, [user, loginReady])

  const [displayUserLoggedOutMessage, setDisplayUserLoggedOutMessage] = React.useState('')
  React.useEffect(() => {
    async function onSessionOverwritten(resp) {
      if (resp.status !== 401) {
        return
      }

      const message = await resp.json()
      if (message?.message !== USER_LOGGED_IN_ON_MULTIPLE_DEVICES_ERROR_CODE && message?.message !== FAILED_TO_AUTHENTICATE_USER) {
        return
      }

      skipRedirect.current = true
      setDisplayUserLoggedOutMessage(message?.message)
      handleLogout()
    }

    subscribeToAuthFetchResponses('handleLogout', onSessionOverwritten)
  }, [])


  const dispatch = async (action) => {
    switch (action.type) {
      case LoginActions.LOGOUT:
        handleLogout()
        break
      default:
        throw new Error(`Unhandled action type: ${action.type.toString()}`)
    }
  }
  return (
    <LoginStateContext.Provider value={{ user, loginReady, displayUserLoggedOutMessage }}>
      <LoginDispatchContext.Provider value={dispatch}>
        {children}
      </LoginDispatchContext.Provider>
    </LoginStateContext.Provider>
  )
}

export const useLogin = () => {
  const state = React.useContext(LoginStateContext)
  const dispatch = React.useContext(LoginDispatchContext)
  if (state === undefined || dispatch === undefined) {
    throw new Error('useLogin must be used within a child of a LoginProider')
  }
  return [state, dispatch]
}
