import React from 'react'

import { postSim } from '../../common/utils'
import { useLogin } from '../../common/LoginContext'

import { useMembers } from '../../contexts/MembersContext'
import { useLocation } from 'react-router-dom'
import { useGroups, groupsController } from '../../contexts/GroupContext'
import { encodeLessonAccessCode, decodeLessonAccessCode, generateDefaultMissionPackIdArray } from './lesson-access-code-operations'

const LESSON_ACCESS_TYPES = Object.freeze({
  LOCKER: 'locker',
  UNLOCKER: 'unlocker',
})

class LessonAccessManagerController {
  setFunctions(funcs) {
    Object.keys(funcs).forEach(key => this[key] = funcs[key])
  }
}
const lamControl = new LessonAccessManagerController()

const LessonAccessStateContext = React.createContext()
function LessonAccessProvider({ children }) {
  const { selectedGroupMissionPack, selectedGroupId, selectedGroup } = useGroups()
  const { members, updateMember, updateMembers, selectedMemberId, getMembers } = useMembers()
  const [loginState] = useLogin()
  const location = useLocation()
  const [userList, setUserList] = React.useState([])
  const [selectedListUsers, setSelectedListUsers] = React.useState([])
  const [selectedUserAccess, setSelectedUserAccess] = React.useState(null)
  const [postingCodeState, setPostingCodeState] = React.useState(null)
  const [detailSelectionDialogOpen, setDetailSelectionDialogOpen] = React.useState(false)
  const [overwriteDialogOpen, setOverwriteDialogOpen] = React.useState(false)
  const [overwriteIndividualSettings, setOverwriteIndividualSettings] = React.useState(false)
  const [changesMadeToSelectedMissionPackIds, setChangesMadeToSelectedMissionPackIds] = React.useState(false)

  const [selectedLessonAccessType, setSelectedLessonAccessType] = React.useState(LESSON_ACCESS_TYPES.UNLOCKER)
  const unlockerSelected = selectedLessonAccessType === LESSON_ACCESS_TYPES.UNLOCKER
  const lockerSelected = selectedLessonAccessType === LESSON_ACCESS_TYPES.LOCKER

  const [selectedUnlockedMissionPackIds, setSelectedUnlockedMissionPackIds] = React.useState([])
  const [selectedLockedMissionPackIds, setSelectedLockedMissionPackIds] = React.useState([])
  const setSelectedMissionPackIds = unlockerSelected ? setSelectedUnlockedMissionPackIds : setSelectedLockedMissionPackIds
  const selectedMissionPackIds = unlockerSelected ? selectedUnlockedMissionPackIds : selectedLockedMissionPackIds

  const selectableLessonItems = React.useRef([])
  const singleUserMode = location.pathname.includes('member')

  const selectedUserAccessMember = userList.find(x => x.userId === selectedUserAccess)
  const allUserListSelected = userList.length === selectedListUsers.length
  const allLessonItemsSelected = selectableLessonItems.current.length === selectedMissionPackIds.length

  const overwritableMembers = members.filter(member => !!member?.lessonUnlockCode || !!member?.lessonLockCode).length
  const membersAreOverwritable = overwritableMembers > 0

  const lessonAccessCode = React.useRef('')
  function updateLessonAccessCode(obj) {
    if (unlockerSelected) {
      lessonAccessCode.current = obj.lessonUnlockCode
    } else {
      lessonAccessCode.current = obj.lessonLockCode
    }
    return lessonAccessCode.current
  }

  React.useEffect(() => {
    handleSettingLessonIds()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLessonAccessType])

  React.useEffect(() => {
    function initializeGroupLessonAccess() {
      console.log('SINGLE: ', singleUserMode)
      if (singleUserMode) {
        const sortedMembers = [...members].sort((a, b) => a.email.localeCompare(b.email))
        setUserList(sortedMembers)
        setSelectedUserAccess(selectedMemberId)

        handleSettingLessonIds()
      } else {
        handleSettingLessonIds()
      }
    }

    initializeGroupLessonAccess()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroup, members])

  React.useEffect(() => {
    selectableLessonItems.current = generateDefaultMissionPackIdArray(selectedGroupMissionPack)
  }, [selectedGroupMissionPack])

  function toggleUserListSelection(userId) {
    setSelectedListUsers((z) => {
      const x = [...z]
      const index = x.indexOf(userId)
      if (index === -1) {
        x.push(userId)
      } else {
        x.splice(index, 1)
      }

      return x
    })
  }

  function toggleAllUserListSelection() {
    setSelectedListUsers((x) => {
      if (allUserListSelected) {
        return []
      }

      return userList.map(x => x.userId)
    })
  }

  function handleSettingLessonIds() {
    if (singleUserMode) {
      const newSelectedMember = members.find(x => x.userId === selectedMemberId)
      if (!newSelectedMember) {
        return
      }

      updateLessonAccessCode(newSelectedMember)
    } else {
      updateLessonAccessCode(selectedGroup)
    }

    let selectedMissionIds
    if (lessonAccessCode.current) {
      selectedMissionIds = decodeLessonAccessCode(selectedGroupMissionPack, lessonAccessCode.current)
    } else {
      selectedMissionIds = []
    }

    setSelectedMissionPackIds(selectedMissionIds)
  }

  function selectAllLessonItems() {
    setSelectedMissionPackIds(selectableLessonItems.current)
  }

  function deselectAllLessonItems() {
    setSelectedMissionPackIds([])
  }

  function discardLessonItemChanges() {
    handleSettingLessonIds()
  }

  React.useEffect(() => {
    const lessonUnlockCode = encodeLessonAccessCode(selectedGroupMissionPack, selectedMissionPackIds)
    if (lessonUnlockCode !== lessonAccessCode.current) {
      setChangesMadeToSelectedMissionPackIds(true)
    } else {
      setChangesMadeToSelectedMissionPackIds(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonAccessCode.current, selectedMissionPackIds])

  function toggleMultipleSelectedLessonContentIds(idArr) {
    if (unlockerSelected) {
      return setSelectedMissionPackIds((z) => {
        var x = [...z]
        for (const id of idArr) {
          const idx = x.indexOf(id)
          if (idx === -1) {
            x = [...x, id]
          } else {
            x = x.filter(y => y !== id)
          }
        }
        return x
      })
    }

    setSelectedMissionPackIds((z) => {
      if (z.length === 0 || z[0] !== idArr[0]) {
        return [...selectableLessonItems.current.slice(selectableLessonItems.current.indexOf(idArr[0]))]
        // return [...idArr]
      }

      return []
    })
  }

  function toggleSelectedMissionContentRow(missionId) {
    const mission = selectedGroupMissionPack.missions.find(x => x.id === missionId)
    toggleMultipleSelectedLessonContentIds([missionId, ...mission.flow.map(x => x.id)])
  }

  async function postGroupCode() {
    const code = encodeLessonAccessCode(selectedGroupMissionPack, selectedMissionPackIds)
    return await postSim(`groups/${selectedGroupId}/lessonAccessCodes`, loginState?.user, {
      code,
      overwrite: overwriteIndividualSettings,
      lessonAccessCodeType: selectedLessonAccessType,
    })
  }

  async function postMemberLessonAccessCodes(memberIds) {
    const code = encodeLessonAccessCode(selectedGroupMissionPack, selectedMissionPackIds)
    return await postSim(`groups/${selectedGroupId}/memberLessonAccessCodes`, loginState?.user, {
      code,
      memberIds,
      lessonAccessCodeType: selectedLessonAccessType,
    })
  }

  async function postMultipleCodes() {
    return await postMemberLessonAccessCodes(selectedListUsers)
  }

  async function postSingleCode() {
    if (singleUserMode) {
      return await postMemberLessonAccessCodes([selectedUserAccess])
    }
    return await postGroupCode()
  }

  function onMultipleCodesSuccess() {
    let updatedObj = {}
    let code = encodeLessonAccessCode(selectedGroupMissionPack, selectedMissionPackIds)
    if (unlockerSelected) {
      updatedObj = { lessonUnlockCode: code }
    } else {
      updatedObj = { lessonLockCode: code }
    }
    updateMembers(selectedListUsers.map(x => [x, updatedObj]))
  }

  function onSingleCodesSuccess() {
    let updatedObj = {}
    let code = encodeLessonAccessCode(selectedGroupMissionPack, selectedMissionPackIds)
    if (unlockerSelected) {
      updatedObj = { lessonUnlockCode: code }
    } else {
      updatedObj = { lessonLockCode: code }
    }

    if (singleUserMode) {
      return updateMember(selectedUserAccess, updatedObj)
    }
    groupsController.updateGroup(selectedGroupId, updatedObj)
    if (overwriteIndividualSettings) {
      return getMembers()
    }
    // updateMember(selectedUserAccess, { lessonUnlockCode: encodeLessonAccessCode(selectedGroupMissionPack, selectedMissionPackIds) })
  }

  function toggleDetailSelectionDialogOpen() {
    setDetailSelectionDialogOpen(x => !x)
  }

  function toggleOverwriteDialogOpen() {
    setOverwriteDialogOpen(x => !x)
  }

  lamControl.setFunctions({
    setSelectedLessonAccessType,
    toggleUserListSelection,
    toggleAllUserListSelection,
    toggleSelectedMissionContentRow,
    setPostingCodeState,
    toggleDetailSelectionDialogOpen,
    postMultipleCodes,
    postSingleCode,
    onMultipleCodesSuccess,
    onSingleCodesSuccess,
    selectAllLessonItems,
    deselectAllLessonItems,
    discardLessonItemChanges,
    toggleOverwriteDialogOpen,
    setOverwriteIndividualSettings,
  })

  return (
    <LessonAccessStateContext.Provider
      value={{
        selectedLessonAccessType,
        userList,
        selectedListUsers,
        selectedUserAccess,
        selectedMissionPackIds,
        selectedUserAccessMember,
        allUserListSelected,
        allLessonItemsSelected,
        postingCodeState,
        detailSelectionDialogOpen,
        singleUserMode,
        changesMadeToSelectedMissionPackIds,
        unlockerSelected,
        lockerSelected,
        selectableLessonItems: selectableLessonItems.current,
        overwriteDialogOpen,
        overwritableMembers,
        membersAreOverwritable,
        overwriteIndividualSettings,
      }}>
      {children}
    </LessonAccessStateContext.Provider>
  )
}

function useLessonAccess() {
  return React.useContext(LessonAccessStateContext)
}

export { LessonAccessProvider, useLessonAccess, LESSON_ACCESS_TYPES, lamControl }