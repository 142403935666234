import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button , Typography } from '@material-ui/core'

import { RespButton } from '../../common/buttons'
import GenericDialog from '../../common/GenericDialog'


const useStyles = makeStyles(theme => ({
  dialogContentContainer: {
    width:700,
    display:'flex',
    padding: '5px 0px',
    gap: 20,
  },
}))


function ConsumesLicenseDialog(props) {
  const classes = useStyles()
  const memberFields = props.memberFields
  const memberData = props.memberData

  const sharedGroups = props.sharedGroups

  const successCb = () => {
    props.successCb()
    props.onClose()
  }

  const ClassTacs = () => {
    return (
      <div style={{display: 'flex', alignItems:'center', gap: 5}}>
        {sharedGroups.map((group, index) => {
          return (
            <Typography
              key={index}
              style={{borderRadius: 3, backgroundColor: 'rgba(0, 0, 0, 0.1)', border: '1px solid rgba(0, 0, 0, 0.3)', padding: '2px 5px'}}
            >
              {group.groupName}
            </Typography>
          )
        })}
      </div>)
  }

  return (
    <GenericDialog
      open={props.open}
      onClose={props.onClose}
      keepMounted
      title={
        <Typography variant='subtitle1'>
          {memberFields.consumesLicense ?
            'Remove Member From Assigned License':
            'Add Member To Assigned License'
          }
        </Typography>
      }
      buttons={
        <>
          <RespButton
            variant='contained'
            color='secondary'
            buttonComponent={Button}
            onClick={props.respButtonOnClick}
            successCb={successCb}
            status={props.fetchStatus}
            setStatus={props.setFetchStatus}
            resetStatus={!props.open}
          >
            Release Consumed License
          </RespButton>
          <Button
            onClick={props.onClose}
            variant='outlined'
          >
          Close
          </Button>
        </>
      }
    >
      <div className={classes.dialogContentContainer}>
        <div style={{display: 'flex', flexDirection: 'column', alignItems:'center', gap: 20}}>
          <Typography>
            {`The license you consume (ID: ${memberData?.license}) is shared amongst the classes below.`}
          </Typography>
          <ClassTacs />
          <Typography>
            {`You consume one license across all ${sharedGroups.length} class(es). If you omit yourself from the license, you'll also lose your seat in the above classes.`}
          </Typography>
          <Typography>
            {'Would you like to proceed?'}
          </Typography>
        </div>
      </div>
    </GenericDialog>
  )
}

export { ConsumesLicenseDialog }