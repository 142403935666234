import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Typography } from '@material-ui/core'

import { RespButton } from '../../common/buttons'
import GenericDialog from '../../common/GenericDialog'
import { OutlinedCheckBox } from '../../common/fields'

import { useSnackbar } from 'notistack'
import { useLessonAccess, lamControl } from './LessonAccessContext'
import { displayLessonAccessInfoSnackbar } from './display-lesson-access-info-snackbar'


const useStyles = makeStyles((theme) => {
  return {
    mainContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '10px 20px',
    },
  }
})

function OverwriteDialog() {
  const snacks = useSnackbar()
  const classes = useStyles()
  const {
    overwriteDialogOpen,
    overwritableMembers,
    postingCodeState,
    overwriteIndividualSettings,
  } = useLessonAccess()
  const {
    onSingleCodesSuccess,
    setPostingCodeState,
    postSingleCode,
    toggleOverwriteDialogOpen,
    setOverwriteIndividualSettings,
  } = lamControl

  function onSuccess() {
    displayLessonAccessInfoSnackbar(snacks)
    snacks.enqueueSnackbar('Successfully applied changes to user(s).', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      autoHideDuration: 2000,
    })
    onSingleCodesSuccess()
    toggleOverwriteDialogOpen()
  }


  return (
    <GenericDialog
      open={overwriteDialogOpen}
      onClose={toggleOverwriteDialogOpen}
      keepMounted
      title={
        <Typography variant='subtitle1'>
          {'Select Users to Apply Lesson Access'}
        </Typography>
      }
      buttons={
        <>
          <RespButton
            variant='contained'
            color='secondary'
            buttonComponent={Button}
            onClick={postSingleCode}
            successCb={onSuccess}
            status={postingCodeState}
            setStatus={setPostingCodeState}
            resetStatus={!overwriteDialogOpen}
            style={{border: '1px solid rgba(0,0,0,0.2)'}}
            disableElevation
          >
            {'Apply changes to class'}
          </RespButton>
          <Button
            onClick={toggleOverwriteDialogOpen}
            variant='outlined'
          >
            {'Close'}
          </Button>
        </>
      }
    >
      <div className={classes.mainContainer}>
        <Typography style={{paddingBottom: 5}}>
          {`${overwritableMembers} student${overwritableMembers === 1 ? '' : 's'} in your class ${overwritableMembers === 1 ? 'has' : 'have'} student-level lesson access settings that override the class-level settings.`}
        </Typography>
        <Typography style={{paddingBottom: 15}}>
          {'Changes made to the class won\'t affect them.'}
        </Typography>
        <Typography variant='h6' style={{paddingBottom: 20}}>
          {'Would you like your changes to override student-level settings?'}
        </Typography>
        <div style={{display: 'flex'}}>
          <OutlinedCheckBox
            onClick={(ev)=>{
              setOverwriteIndividualSettings(x => !x)
            }}
            label='Override student-level settings'
            checked={overwriteIndividualSettings}
          />
        </div>
      </div>
    </GenericDialog>
  )
}

export { OverwriteDialog }