import Rollbar from 'rollbar'

const useRollbar = process.env.NODE_ENV !== 'development' && process.env.REACT_APP_PRODUCTION_USE_ROLLBAR === 'y'

const rollbar = new Rollbar({
  enabled: useRollbar,
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  logLevel: 'info',
  payload: {
    environment: process.env.NODE_ENV,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: process.env.REACT_APP_GIT_SHA,
        guess_uncaught_frames: true,
      },
    },
  },
  hostBlockList: [
    // GoGuardian Beacon [unlisted Chrome extension]
    // Activity monitor for students
    'chrome-extension://haldlgldplgnggkjaafhelgiaglafanh',
  ],
})

const identifyRollbarUser = ({ id, username, email }) => {
  rollbar.configure({
    payload: {
      person: {
        id,
        username,
        email,
      },
    },
  })
}

const clearRollbarUser = () => {
  rollbar.configure({
    payload: {
      person: null,
    },
  })
}

const sendRollbarError = (error) => {
  rollbar.error(error)
}

export {
  sendRollbarError,
  identifyRollbarUser,
  clearRollbarUser,
}