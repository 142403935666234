import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import AddIcon from '@material-ui/icons/Add'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import SettingsIcon from '@material-ui/icons/Settings'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

import DataTable from '../../common/DataTable'
import { useLogin } from '../../common/LoginContext'
import { putSim, deleteSim } from '../../common/utils'
import { useMissionPacks } from '../../common/MissionPackContext'

import WarningDialog from '../shared-components/WarningDialog'
import PanelTemplate from '../shared-components/PanelTemplate'
import { DeleteActionPanel } from '../shared-components/ActionPanel'

import { GenerateAssignmentsDialog } from './generate-assignments-dialog/GenerateAssignmentsDialog'
import { AddMemberDialog } from './AddMemberDialog'
import { EditGroupActionPanel } from './EditGroupActionPanel'
import { appHeaderHeight } from '../../routes/ClassDashboardSiteHeader'
import { useGroups, groupsController } from '../../contexts/GroupContext'
import { useNavigate } from 'react-router-dom'
import { useMembers } from '../../contexts/MembersContext'
import { handleMultipleStatuses } from '../shared-components/multiple-status-handler'


const useStyles = makeStyles(theme => ({
  dataGrid: {
    backgroundColor: theme.palette.custom.primary,
  },
  bodyContainer: {
    height: '100%',
    display:'flex',
  },
}))

const columns = [
  { field: 'email',
    headerName: 'Email',
    flex: 2,
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 2,
  },
  // {
  //   field: 'consumesLicense',
  //   headerName: 'Consumes License',
  //   renderCell: params => (
  //     params.value ?
  //       <CheckIcon /> :
  //       <CloseIcon />
  //   ),
  //   flex: 1,
  // },
  {
    field: 'overallPercent',
    headerName: 'Overall %',
    flex: 1,
    valueGetter: (params) => {
      return params.value + '%'
    },
  },
  {
    field: 'totalXp',
    headerName: 'XP',
    flex: 1,
  },
  {
    field: 'completedUpTo',
    headerName: 'Completed Up To',
    flex: 2,
  },
  {
    field: 'lastActive',
    headerName: 'Last Active',
    valueGetter: (params) => {
      return !params.value || params.value === '' ? ' - ' : new Date(params.value._seconds*1000 + params.value._nanoseconds/100000).toLocaleString()
    },
    flex: 2,
  },
  {
    field: 'role',
    headerName: 'Role',
    flex: 1,
  },
  {
    field: 'userAssigned',
    headerName: 'Assigned License',
    renderCell: (params) => {
      return params.row.license === '' ? <CloseIcon style={{color: 'red'}}/> : <CheckIcon style={{color: '#4CAF50'}}/>
    },
    width: 147,
  },
]



function GroupPage() {
  const classes = useStyles()
  const { getGroups, handleDeleteGroup } = groupsController
  const {
    selectedGroup,
    groupsFetchStatus,
    selectedGroupId,
  } = useGroups()
  const {
    getMembers,
    members,
    membersFetchStatus,
    setSelectedMemberId,
  } = useMembers()
  const navigate = useNavigate()

  const [openMemberDialog, setOpenMemberDialog] = React.useState(false)
  const [openDeleteMemberDialog, setOpenDeleteMemberDialog] = React.useState(false)
  const [openDeleteClassDialog, setOpenDeleteClassDialog] = React.useState(false)
  const [generateAssignmentsDialogOpen, setGenerateAssignmentsDialogOpen] = React.useState(false)

  const [showActionPanel, setShowActionPanel] = React.useState('settings')
  const [rowsToDelete, setRowsToDelete] = React.useState([])
  const [tableParams, setTableParams] = React.useState({
    headerHeight: null,
    pageSize: 0,
  })

  const [loginState] = useLogin()
  const { missionPacks } = useMissionPacks()
  const rowData = selectedGroup
  const groupId = rowData?.groupId

  const handleDeleteMembers = async (memberIds) => {
    const resp = await Promise.all(memberIds.map(memberId => deleteSim(`groups/${groupId}/members/${memberId}?role=owner`, loginState?.user)))
    return resp.find(({status}) => status !== 200) ?? resp[0]
  }

  const handleDeleteMembersSuccess = () => {
    setRowsToDelete([])
    setShowActionPanel('settings')
    getMembers()
  }

  const handleAddMember = async (memberParams) => {
    var formattedMemberParams = {...memberParams}
    formattedMemberParams.groupId = groupId
    formattedMemberParams.email = formattedMemberParams.email.toLowerCase()
    return putSim(`groups/${groupId}/members`, loginState?.user, {email: memberParams.email.toLowerCase(), role: 'member'})
  }

  const handleAddMemberSuccess = () => {
    setOpenMemberDialog(false)
    getMembers()
  }

  const handleRowSelection = (memberIds) => {
    if (showActionPanel === 'delete'){
      setRowsToDelete(memberIds)
    } else {
      var memberId = memberIds
      const member = members.find(member => member.id === memberId.id)
      navigate(`member/${memberId.id}`, { state: { name: member.name ?? member.email } })
      setSelectedMemberId(memberId.id)
    }
  }

  const openDeleteActionMenu = () => {
    if (showActionPanel === 'delete'){
      setShowActionPanel(false)
      return
    }
    setShowActionPanel('delete')
    setRowsToDelete([])
  }

  const openSettingsActionMenu = () => {
    if (showActionPanel === 'settings'){
      setShowActionPanel(false)
      return
    }
    setShowActionPanel('settings')
  }

  const handleClassChangeSuccess = async () => {
    await getGroups()
  }

  const handleDeleteGroupSuccess = async () => {
    navigate('..')
    await getGroups()
  }

  function deleteGroup() {
    return handleDeleteGroup(selectedGroupId)
  }

  return (
    <PanelTemplate
      deleteMode={showActionPanel === 'delete'}
      headerButtons={[
        {
          title: 'Add Student',
          selected:null,
          onClick:()=>setOpenMemberDialog(true),
          icon: <AddIcon />,
        },
        {
          title: 'Delete Student(s)',
          selected: showActionPanel === 'delete',
          onClick: openDeleteActionMenu,
          icon: <DeleteForeverIcon />,
        },
        {
          title: 'Edit Class',
          selected: showActionPanel === 'settings',
          onClick: openSettingsActionMenu,
          icon: <SettingsIcon />,
        },
      ]}
      mainPanelContent={
        <div className={classes.bodyContainer}>
          <div style={{flexGrow: 1}}>
            <div className={classes.dataGrid} style={{height: '100%'}}>
              <DataTable
                appHeaderHeight={appHeaderHeight}
                defaultSort={{field: 'email', sort: 'asc'}}
                checkColor={showActionPanel === 'delete' ? '#f44336' : null}
                status={handleMultipleStatuses(groupsFetchStatus, membersFetchStatus)}
                tableContent='members'
                addItemComment='Click the + button above to add a new member!'
                style={{borderRadius: 0}}
                rows={members}
                columns={rowData.licenseType === 'Assigned' ?
                  columns :
                  columns.filter(column => column.field !== 'consumesLicense' && column.field !== 'userAssigned')
                }
                isRowSelectable={(params) => {
                  return params.row.role !== 'Owner'
                }}
                autoPageSize
                disableColumnMenu
                checkboxSelection={showActionPanel === 'delete'}
                onSelectionModelChange={(newSelectionModel) => {
                  if (showActionPanel === 'delete'){
                    handleRowSelection(newSelectionModel)
                  }
                }}
                tableParams={tableParams}
                setTableParams={setTableParams}
                onRowClick={(newSelectionModel) => {
                  if (showActionPanel !== 'delete'){
                    handleRowSelection(newSelectionModel)
                  }
                }}
              />
            </div>
          </div>
          <AddMemberDialog
            handleAddMember={handleAddMember}
            handleAddMemberSuccess={handleAddMemberSuccess}
            open={openMemberDialog}
            handleClose={()=>setOpenMemberDialog(false)}
          />
          <WarningDialog
            title={'Delete Member(s)'}
            content={`${rowsToDelete.length} ${rowsToDelete.length > 1 ?  'members' : 'member'}`}
            open={openDeleteMemberDialog}
            handleClose={()=>setOpenDeleteMemberDialog(false)}
            handleDelete={() => handleDeleteMembers(rowsToDelete)}
            handleDeleteSuccess={handleDeleteMembersSuccess}
            rowsSelected={rowsToDelete.length}
          />
          <WarningDialog
            title={'Delete Class'}
            content={'this class'}
            open={openDeleteClassDialog}
            handleClose={()=>setOpenDeleteClassDialog(false)}
            handleDelete={deleteGroup}
            handleDeleteSuccess={handleDeleteGroupSuccess}
            rowsSelected={rowsToDelete.length}
          />
          <GenerateAssignmentsDialog
            open={generateAssignmentsDialogOpen}
            handleOpen={() => setGenerateAssignmentsDialogOpen(true)}
            handleClose={() => setGenerateAssignmentsDialogOpen(false)}
            groupId={groupId}
            lmsId={selectedGroup?.lmsId}
            lmsClassId={selectedGroup?.lmsClassId}
            lmsClassName={selectedGroup?.lmsClassName}
            missionPackId={selectedGroup.missionPackId}
          />
        </div>
      }
      actionPanelOpen={showActionPanel !== false}
      actionPanelContents={
        <div>
          {showActionPanel === 'delete' ?
            <DeleteActionPanel
              title={'Select students to delete'}
              waiting={membersFetchStatus === 'waiting'}
              handleClose={() => setShowActionPanel(false)}
              handleDelete={()=>setOpenDeleteMemberDialog(true)}
              rowsSelected={rowsToDelete.length}
              type={'Student(s)'}
            />:
            showActionPanel === 'settings' ?
              <EditGroupActionPanel
                packList={missionPacks}
                getGroups={handleClassChangeSuccess}
                handleDeleteGroups={()=>setOpenDeleteClassDialog(true)}
                handleClose={() => setShowActionPanel(false)}
                title={'Edit Class'}
                groupId={selectedGroup.groupId}
                classes={classes}
                classData={selectedGroup}
                openGenerateAssignmentsDialog={() => setGenerateAssignmentsDialogOpen(true)}
              />:
              null}
        </div>
      }
    />
  )
}

export { GroupPage }