import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import FilterListIcon from '@material-ui/icons/FilterList'

import QuickFilter from '../../common/QuickFilter'
import { useMissionPacks } from '../../common/MissionPackContext'
import DataTable from '../../common/DataTable'
import WarningDialog from '../shared-components/WarningDialog'
import { DeleteActionPanel, ActionPanelHeader } from '../shared-components/ActionPanel'
import PanelTemplate from '../shared-components/PanelTemplate'

import { appHeaderHeight } from '../../routes/ClassDashboardSiteHeader'
import { AddGroupDialog } from './AddGroupDialog'
import { useGroups, groupsController } from '../../contexts/GroupContext'
import { useNavigate } from 'react-router-dom'
import { handleMultipleStatuses } from '../shared-components/multiple-status-handler'


const useStyles = makeStyles(theme => ({
  dataGrid: {
    borderRadius: 0,
    height: '100%',
    display:'flex',
    backgroundColor: 'white',
    // backgroundColor: theme.palette.custom.secondary,
    color: 'black',
  },
}))

const columns = (missionPacks) => {
  return [
    { field: 'groupName',
      headerName: 'Class Name',
      flex: 4,
    },
    {
      field: 'missionPackId',
      headerName: 'Curriculum',
      valueGetter: (params) => {
        var missionPackId = params.row.missionPackId
        try {
          missionPackId = missionPacks.find(pack => pack.id === params.row.missionPackId).title
        } catch (err){
        }
        return missionPackId
      },
      flex: 4,
    },
    {
      field: 'joinCode',
      headerName: 'Join Code',
      flex: 3,
    },
    {
      field: 'joinActive',
      headerName: 'Join Active',
      renderCell: params => (
        params.value ?
          <CheckIcon /> :
          <CloseIcon />
      ),
      width: 125,
    },
    {
      field: 'dateCreated',
      headerName: 'Date Created',
      valueGetter: (params) => {
        return new Date(params.row.created).toLocaleDateString()
      },
      flex: 3,
    },

  ]
}

function GroupsOverviewPage(props) {
  const classes = useStyles()
  const { setSelectedGroupId, getGroups, handleDeleteGroups } = groupsController
  const { groups, groupsFetchStatus } = useGroups()
  const navigate = useNavigate()

  const [classroomDialog, setClassroomDialog] = React.useState(false)
  const [filteredGroups, setFilteredGroups] = React.useState(null)
  const [deleteWarningOpen, setDeleteWarningOpen] = React.useState(false)
  const [showActionPanel, setShowActionPanel] = React.useState('settings')
  const [rowsToDelete, setRowsToDelete] = React.useState([])
  const { missionPacks, fetchPacksStatus } = useMissionPacks()
  const [tableParams, setTableParams] = React.useState({
    headerHeight: null,
    pageSize: 0,
  })

  const handleRowClick = async (rows) => {
    if (!Array.isArray(rows)) {
      return
    }
    var rowId = rows
    if (rows.length > 1){
      rowId = rows[1]
    } else {
      rowId = rows[0]
    }
    if (!rowId){
      return
    }

    setSelectedGroupId(rowId)
    navigate('group/'+rowId)
  }

  const handleSelectionModelChange = (row) => {
    if (showActionPanel === 'delete'){
      setRowsToDelete(row)
    } else if (showActionPanel === 'settings'){
      handleRowClick(row)
    } else {
      navigate('group')
      handleRowClick(row)
    }
  }

  const openDeleteActionMenu = () => {
    if (showActionPanel === 'delete'){
      setShowActionPanel(false)
      return
    }
    setShowActionPanel('delete')
    setRowsToDelete([])
  }

  const toggleSettingsActionMenu = () => {
    if (showActionPanel === 'settings'){
      setShowActionPanel(false)
      return
    }
    setShowActionPanel('settings')
  }

  function onDeleteClassSuccess() {
    setShowActionPanel('settings')
    getGroups()
  }

  return (
    <PanelTemplate
      deleteMode={showActionPanel === 'delete'}
      headerButtons={[
        {
          title: 'Add Classroom',
          selected:null,
          onClick:() => setClassroomDialog('new'),
          icon: <AddIcon />,
        },
        {
          title: 'Delete Classroom(s)',
          selected: showActionPanel === 'delete',
          onClick: openDeleteActionMenu,
          icon: <DeleteForeverIcon />,
        },
        {
          title: 'Filters',
          selected: showActionPanel === 'settings',
          onClick: toggleSettingsActionMenu,
          icon: <FilterListIcon />,
        },
      ]}
      leftPanelContent={
        null
      }
      mainPanelContent={
        <div style={{width: '100%', height: '100%', display:'flex'}}>
          <div  style={{width: '100%', height: '100%'}}>
            <div className={classes.dataGrid}>
              <DataTable
                appHeaderHeight={appHeaderHeight}
                checkColor={showActionPanel === 'delete' ? '#f44336' : null}
                defaultSort={{field: 'groupName', sort: 'asc'}}
                tableContent='classes'
                addItemComment='Click the + button above to add a new class!'
                status={handleMultipleStatuses(fetchPacksStatus, groupsFetchStatus )}
                rows={filteredGroups ? filteredGroups : groups}
                columns={columns(missionPacks, classes)}
                // pageSize={14}
                // rowsPerPageOptions={[10]}s
                autoPageSize
                checkboxSelection={showActionPanel !== false && showActionPanel !== 'settings'}
                onSelectionModelChange={handleSelectionModelChange}
                tableParams={tableParams}
                setTableParams={setTableParams}
                disableColumnMenu
                onRowClick={handleSelectionModelChange}
              />
            </div>
            <AddGroupDialog
              packList={missionPacks}
              open={classroomDialog !== false}
              handleClose={()=>setClassroomDialog(false)}
              dialogState={classroomDialog}
              classes={classes}
              getGroups={getGroups}
            />
          </div>
          <WarningDialog
            title={'Delete Class'}
            content={`${rowsToDelete.length} ${rowsToDelete.length > 1 ?  'classes' : 'class'}`}
            open={deleteWarningOpen}
            handleClose={()=>setDeleteWarningOpen(false)}
            handleDelete={() => handleDeleteGroups(rowsToDelete)}
            handleDeleteSuccess={onDeleteClassSuccess}
            rowsSelected={rowsToDelete.length}
          />
        </div>
      }
      actionPanelOpen={showActionPanel !== false}
      actionPanelHandleClose={() => setShowActionPanel(false)}
      actionPanelTitle={
        showActionPanel === 'delete' ?
          'Select classes to delete' : ''
      }
      actionPanelContents={
        <div>
          {
            showActionPanel === 'delete' ?
              <DeleteActionPanel
                title={'Select groups to delete'}
                waiting={groupsFetchStatus === 'waiting'}
                handleClose={() => setShowActionPanel(false)}
                handleDelete={()=>setDeleteWarningOpen(true)}
                rowsSelected={rowsToDelete.length}
                type={'Group(s)'}
              />:
              showActionPanel === 'settings' ?
                <>
                  <ActionPanelHeader
                    title={'Filters'}
                    handleClose={() => setShowActionPanel(false)}
                  />
                  <QuickFilter
                    onClose={toggleSettingsActionMenu}
                    height={'2em'}
                    rows={groups}
                    setFilteredRows={setFilteredGroups}
                  />
                </>:
                null
          }
        </div>
      }
    />
  )
}

export { GroupsOverviewPage }