import React from 'react'
import { CircularProgress, Typography, Button, List, ListItem, Checkbox } from '@material-ui/core'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

function AssignmentSelectionRow({ title, locked, index, selected, onClick }) {
  return (
    <ListItem
      key={title}
      dense
      button
      divider
      style={{
        padding: 0,
      }}
      onClick={() => onClick(title)}
      disabled={!!locked}
    >
      <div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between', width: '100%'}}>
        <Checkbox
          checked={selected}
          style={{color:  selected && !locked ? 'rgba(76, 175, 79)' : 'rgba(0,0,0,0.4)'}}
          disableRipple
          checkedIcon={<CheckBoxIcon />}
        />
        <div style={{color: selected ? null : 'rgba(0,0,0,0.55)'}}>{title}</div>
        <div style={{width: 24}}></div>
      </div>
    </ListItem>
  )
}

function PostAssignmentStatePanel({ postingAssignmentState }) {
  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 400}}>
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        <Typography style={{fontSize: 14}}>
          {`Generating Assignment: ${postingAssignmentState.index + 1}/${postingAssignmentState.total}`}
        </Typography>
        <div style={{height: 10}}></div>
        <Typography>
          {postingAssignmentState.title}
        </Typography>
      </div>
      <div style={{height: 40}}></div>
      <CircularProgress style={{color: '#4CAF5066'}} size={55}/>
      <div style={{height: 40}}></div>
    </div>
  )
}

function AssignmentGenerationSelectionPanel({
  lmsClassId,
  lmsClassName,
  selectedAssignments,
  setSelectedAssignments,
  missionPackCuratedAssignments,
  setMissionPackCuratedAssignments,
  postingAssignmentState,
  fetchingAssignments,
}){
  const allAssignmentsSelected = Object.keys(selectedAssignments).length === 0
  const selectableAssignments = missionPackCuratedAssignments.filter(assignment => !assignment.locked)
  const selectableAssignmentsLength = selectableAssignments.length
  function handleListItemClick(assignmentTitle) {
    setSelectedAssignments((selectedAssignments) => {
      const copyOfSelectedAssignments = { ...selectedAssignments }
      if (assignmentTitle in copyOfSelectedAssignments) {
        delete copyOfSelectedAssignments[assignmentTitle]
      } else {
        copyOfSelectedAssignments[assignmentTitle] = true
      }
      return copyOfSelectedAssignments
    })
  }

  function handleAssignmentListToggleAll(){
    if (!allAssignmentsSelected) {
      setSelectedAssignments({})
      return
    }

    const newSelectedAssignments = {}
    selectableAssignments.forEach(assignment => newSelectedAssignments[assignment.title] = true)
    setSelectedAssignments(newSelectedAssignments)
  }

  return (
    <div>
      {!!postingAssignmentState ? <PostAssignmentStatePanel postingAssignmentState={postingAssignmentState}/>:null}
      {fetchingAssignments ?
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 400}}>
          <Typography>{`Fetching class ${lmsClassName} assignments.`}</Typography>
          <div style={{height: 40}}></div>
          <CircularProgress style={{color: '#4CAF5066'}} size={55}/>
          <div style={{height: 40}}></div>
        </div>:
        null}
      {!postingAssignmentState && !fetchingAssignments ?
        <div >
          {selectableAssignmentsLength === 0 ?
            <div style={{display: 'flex', alignItems:'center', flexDirection:'column'}}>
              <Typography>
                {'All available assignments have been generated for this class.'}
              </Typography>
              <Typography style={{fontSize: 14}}>
                {'If you\'d like to delete an assignment, do so in Google Classroom.'}
              </Typography>
              <div style={{height:5}}></div>
            </div>:null}
          <>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 5}}>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'flex-end'}}>
                <div style={{fontSize: 15, color: selectableAssignmentsLength === 0 ? 'rgba(0,0,0,0.55)' : null}}>{'Assignment Selection'}</div>
                <div style={{fontSize: 13, paddingLeft: 5, color: selectableAssignmentsLength === 0 ? 'rgba(0,0,0,0.55)' : 'rgba(0,0,0,0.8)'}}>
                  {`${selectableAssignmentsLength - Object.keys(selectedAssignments).length}/${selectableAssignmentsLength}`}
                </div>
              </div>
              <Button
                size='small'
                variant='outlined'
                onClick={handleAssignmentListToggleAll}
                style={{ width: 120 }}
                disabled={selectableAssignmentsLength === 0}
              >
                {allAssignmentsSelected ? 'Deselect All' : 'Select All'}
              </Button>
            </div>
            <List style={{
              width: '100%',
              height: selectableAssignmentsLength === 0 ? 465 : 515,
              overflowY: 'scroll',
              borderStyle: 'solid',
              borderWidth: 1,
              borderColor: 'rgba(0,0,0,0.2)',
              borderRadius: 1,
              padding: 0,
            }}>
              {missionPackCuratedAssignments.map((assignment, idx) =>
                <AssignmentSelectionRow
                  key={assignment.title}
                  title={assignment.title}
                  index={idx}
                  selected={!(assignment.title in selectedAssignments)}
                  locked={assignment.locked}
                  onClick={handleListItemClick}
                />
              )}
            </List>
            <div style={{height: 5}}></div>
          </>
        </div>:
        null}
    </div>
  )
}

export { AssignmentGenerationSelectionPanel }
