import React from 'react'
import fetchHandler from './tools/tracking/fetchHandler'

// This was added to prevent development packs from being visible in the quick filter.
// In the future, making multiple pack sections in the missionPacks.json would be a better solution.
const devPacks = []

const MissionPackContext = React.createContext()
export const MissionPackProvider = (props) => {
  const [packs, setPacks] = React.useState([])
  const [packsInitialized, setPacksInitialized] = React.useState(false)
  const [fetchPacksStatus, setFetchPacksStatus] = React.useState('waiting')
  const pollTimeout = React.useRef(null)

  React.useEffect(() => {
    const domain = `https://${process.env.REACT_APP_LESSON_CONTENT_BASE_URI}`

    const contentFetch = async (url, blob=false) => {
      if (!blob) {
        const resp = await fetchHandler(`${domain}/lesson-content/${url}`,
          {
            method: 'GET',
            headers : {
              'Content-Type': 'application/json',
            },
          }
        )
        return await resp.json()
      }
      const response = await fetchHandler(`${domain}/lesson-content/${url}`
        ,{
          headers : {
            'Content-Type': 'application/octet-stream',
            'Accept': 'application/octet-stream',
          },
        }
      )
      return response.blob()
    }

    const getMissionPacks = async (removeFreePack=true) => {
      let packData = []

      var packList = await contentFetch('missionPackList.json')
      packList = packList.filter(pack => !devPacks.includes(pack))

      if (props?.filteredPackIds) {
        packList = packList.filter(packName => !props?.filteredPackIds.includes(packName))
      } else {
        packList = packList.filter(packName => !packName.startsWith('Free'))
      }

      const packPromises = packList.map(async (packId)=>{
        return await contentFetch(`packed/${packId}.json`)
      })
      packData = await Promise.all(packPromises)
      setPacks(packData)
    }

    const pollPacks = async (retries=3) => {
      try {
        await getMissionPacks()
      } catch (err) {
        if (retries > 0) {
          pollPacks(retries - 1)
          return
        } else {
          setFetchPacksStatus('error')
          return
        }
      }

      setPacksInitialized(true)
      setFetchPacksStatus('success')
    }

    pollPacks()
    pollTimeout.current = setInterval(()=>{
      pollPacks()
    }, 5 * 60 * 1000)

    return () => {
      clearInterval(pollTimeout.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <MissionPackContext.Provider value={{ missionPacks: packs, missionPacksInitialized: packsInitialized, fetchPacksStatus }}>
      {props.children}
    </MissionPackContext.Provider>
  )
}

export const useMissionPacks = () => {
  const packs = React.useContext(MissionPackContext)
  if (packs === null) {
    throw new Error('useLogin must be used within a child of a MissionPackProvider')
  }
  return packs
}
