import React from 'react'
import { CircularProgress, Typography, Button } from '@material-ui/core'

import { useLogin } from '../../../common/LoginContext'
import GenericDialog from '../../../common/GenericDialog'
import { getSim, postSim } from '../../../common/utils'
import { FiriaGreenButton } from '../../../common/buttons'
import { useMissionPacks } from '../../../common/MissionPackContext'
import { AssignmentGenerationSelectionPanel } from './AssignmentGenerationSelectionPanel'
import { LMS3LegAuthorizationPanel } from '../../shared-components/lms-panels/LMS3LegAuthorizationPanel'

import { CURATED_ASSIGNMENTS_BY_MISSION_PACK_ID } from './curated-assignments'

function generateAssignmentContent(missionPackId, missionPacks) {
  let totalAssignments = 0
  return CURATED_ASSIGNMENTS_BY_MISSION_PACK_ID[missionPackId].map(({missionId, flowIds}) => {
    const missionPack = missionPacks.find(({id}) => id === missionPackId)
    const missionIdx = missionPack.missions.findIndex(({id}) => missionId === id)
    const mission = missionPack.missions[missionIdx]
    return flowIds.map((flowId) => {
      const flowIdx = mission.flow.findIndex(({id}) => id === flowId)
      const flow = mission.flow[flowIdx]
      totalAssignments += 1
      return {
        title: `Assignment ${totalAssignments}: ${flow.obj.title} (${mission.title})`, // `Mission ${missionIdx + 1}: ${mission.title} - Objective ${flowIdx + 1 - intermediaryQuizzes}: ${flow.title}`,
        missionId,
        flowId,
      }
    })
  }).flat()
}


async function postAssignment(user, lmsId, groupId, lmsClassId, missionId, flowId, title, lmsAssignmentData) {
  try {
    const resp = await postSim(`groups/${groupId}/assignment`, user, {
      title,
      missionId,
      flowId,
      lmsId,
      lmsClassId,
      lmsAssignmentData,
    })
    if (resp.status !== 200) {
      return resp.status
    }
  } catch (err) {
    return err
  }
}

async function getLMSAssignments(user, lmsId, classId) {
  try {
    const resp = await getSim(`lms/${lmsId}/${classId}/assignment-titles`, user)
    const data = await resp.json()
    if (resp.status === 200) {
      return { status: resp.status, data }
    }

    return { status: resp.status, data: null, error: data?.message }
  } catch (err) {
    //
  }
}

let setAuthorizingState = () => {}
const getAuthorizationURL = async (user, lmsId) => {
  try {
    setAuthorizingState(new Date())
    const resp = await getSim(`lms/${lmsId}/authorization/3-leg?role=teacher`, user)
    const data = await resp.text()
    if (data && resp.status === 200) {
      window.open(data, '_blank')
    }
  } catch (err) {
    // empty
  }
}

function FetchingAssignmentsPanel() {
  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height:'100%'}}>
      <Typography>
        {'Fetching Assignments'}
      </Typography>
      <div style={{height: 40}}></div>
      <CircularProgress style={{color: '#4CAF5066'}} size={55}/>
      <div style={{height: 40}}></div>
    </div>
  )
}

function GetAuthorizationButton({ makeButton, func }) {
  const [openingTab, setOpeningTab] = React.useState(false)

  const openAuthTab = async () => {
    setOpeningTab(true)
    await func()
    setOpeningTab(false)
  }

  return (
    <div style={{display: 'flex', alignItems:'center'}}>
      {openingTab ?
        <div style={{display: 'flex', alignItems: 'center'}}>
          <CircularProgress size={16}/>
          <div style={{width: 10}}></div>
        </div> :
        <div style={{width: 26}}></div>}
      {makeButton(openAuthTab, openingTab)}
    </div>
  )
}

function GenerateAssignmentsDialog({
  open,
  handleClose,
  groupId,
  missionPackId,
  lmsId,
  lmsClassId,
  lmsClassName,
}) {
  const [loginState] = useLogin()
  const { missionPacks } = useMissionPacks()
  const [selectedAssignments, setSelectedAssignments] = React.useState({})
  const [missionPackCuratedAssignments, setMissionPackCuratedAssignments] = React.useState([])
  const [authorizing, setAuthorizing] = React.useState(false)
  const [authorizationRequired, setAuthorizationRequired] = React.useState(false)
  const [fetchingAssignments, setFetchingAssignments] = React.useState(false)
  const [postAssignmentsInProgress, setPostAssignmentsInProgress] = React.useState(false)
  const [authStatus, setAuthStatus] = React.useState(null)
  const curatedAssignments = React.useRef(null)
  const authorizationSucceeded = authStatus?.succeeded
  setAuthorizingState = (e) => {
    setAuthorizing(e)
    setAuthStatus(null)
  }

  const handleGettingGoogleCourseAssignments = async () => {
    setFetchingAssignments(true)
    const resp = await getLMSAssignments(loginState?.user, lmsId, lmsClassId)
    if (!resp) {
      return
    }

    const { data, status, error } = resp
    if (status === 401 && error?.type === 'LMS') {
      setAuthorizationRequired(true)
      setFetchingAssignments(false)
      return
    }

    setAuthorizationRequired(false)
    setMissionPackCuratedAssignments(curatedAssignments.current.map(assignment =>
      ({ ...assignment, locked: !!data.find(googleCourseTitle => googleCourseTitle === assignment.title) })
    ))
    setFetchingAssignments(false)
    setSelectedAssignments({})
  }

  const lastPostingQueueEndTs = React.useRef(null)
  const handlePostingAssignments = async () => {
    const deselectedKeys = Object.keys(selectedAssignments)
    const assignmentsToPost = missionPackCuratedAssignments.filter(assignment => !assignment.locked && !deselectedKeys.find(deselectedTitle => deselectedTitle === assignment.title))
    for (let i=0; i < assignmentsToPost.length; i++) {
      const assignment = assignmentsToPost[i]
      setPostAssignmentsInProgress({index: i, total: assignmentsToPost.length, title: assignmentsToPost[i].title})
      await postAssignment(loginState?.user, lmsId, groupId, lmsClassId, assignment.missionId, assignment.flowId, assignment.title, assignment)
    }
    lastPostingQueueEndTs.current = Date.now()
    setPostAssignmentsInProgress(false)
    handleGettingGoogleCourseAssignments()
  }

  React.useEffect(() => {
    if (open) {
      setAuthStatus(null)
      curatedAssignments.current = generateAssignmentContent(missionPackId, missionPacks)
      handleGettingGoogleCourseAssignments()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const numberOfSelectedAssignments = missionPackCuratedAssignments.filter(assignment => !assignment.locked).length - Object.keys(selectedAssignments).length
  const LOADING_SPINNER_OPEN = fetchingAssignments
  const AUTHORIZATION_REQUIRED_PANEL_OPEN = Boolean((authorizationRequired && !authStatus) || authStatus?.error)
  const AWAITING_AUTH_PANEL_OPEN = authorizing && !authStatus?.completed && !AUTHORIZATION_REQUIRED_PANEL_OPEN
  const CLASS_SELECTION_PANEL_OPEN = (authorizationSucceeded || !authStatus) && !LOADING_SPINNER_OPEN && !AUTHORIZATION_REQUIRED_PANEL_OPEN && !AWAITING_AUTH_PANEL_OPEN
  return (
    <GenericDialog
      open={open}
      handleClose={handleClose}
      keepMounted
      TransitionProps={{
        onExited: () => {
          setAuthorizationRequired(false)
          setAuthorizing(false)
          setPostAssignmentsInProgress(false)
          setFetchingAssignments(false)
        },
      }}
      title={
        <Typography variant='subtitle1'>
          {'Generate Assignments'}
        </Typography>
      }
      buttons={
        <>
          {CLASS_SELECTION_PANEL_OPEN ?
            <FiriaGreenButton
              disabled={!!postAssignmentsInProgress || numberOfSelectedAssignments === 0 || !!fetchingAssignments}
              variant='contained'
              onClick={handlePostingAssignments}
            >
              {`Generate (${numberOfSelectedAssignments}) Assignment(s)`}
            </FiriaGreenButton>:
            null}
          {AWAITING_AUTH_PANEL_OPEN ? <>
            <GetAuthorizationButton makeButton={(onClick, disabled=false) =>
              <Button variant='contained' onClick={onClick} disabled={!!disabled}>
                {'Retry'}
              </Button>
            } func={() => getAuthorizationURL(loginState?.user, lmsId)}/>
          </>: null}
          <Button
            onClick={handleClose}
            variant='outlined'
            disabled={!!postAssignmentsInProgress}
          >
            {'Close'}
          </Button>
        </>
      }
    >
      <div style={{width: 700, height: 550}}>
        {authorizationRequired && !fetchingAssignments ?
          <div style={{height: '100%', width: '100%', display: 'flex', alignItems:'center', justifyContent: 'center'}}>
            <LMS3LegAuthorizationPanel
              authorizationRequired={authorizationRequired}
              setAuthorizationRequired={setAuthorizationRequired}
              authorizationSuccessCb={handleGettingGoogleCourseAssignments}
              lmsId={lmsId}
            />
          </div>:
          null}
        {fetchingAssignments ?
          <FetchingAssignmentsPanel />:
          null}
        {!authorizationRequired && !fetchingAssignments ?
          <AssignmentGenerationSelectionPanel
            setPostAssignmentsInProgress={setPostAssignmentsInProgress}
            handleClose={handleClose}
            groupId={groupId}
            lmsClassId={lmsClassId}
            lmsClassName={lmsClassName}
            curatedAssignments={curatedAssignments.current}
            selectedAssignments={selectedAssignments}
            setSelectedAssignments={setSelectedAssignments}
            missionPackCuratedAssignments={missionPackCuratedAssignments}
            setMissionPackCuratedAssignments={setMissionPackCuratedAssignments}
            postingAssignmentState={postAssignmentsInProgress}
            lastPostingQueueEndTs={lastPostingQueueEndTs.current}
            fetchingAssignments={fetchingAssignments}
          />:
          null}
      </div>
    </GenericDialog>
  )
}

export { GenerateAssignmentsDialog }