import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { Typography, IconButton, Drawer, Tooltip } from '@material-ui/core'

import { DeleteButton } from '../../common/buttons'

import { appHeaderHeight, rightDrawerWidth  } from '../../routes/ClassDashboardSiteHeader'


const useStyles = makeStyles(theme => ({
  paper: {
    flexShrink: 0,
    top: appHeaderHeight,
    width: rightDrawerWidth,
    height: '100%',
    backgroundColor: 'white',
    borderLeft: '0px',
  },
  drawerHeader: {
    borderBottom: `1px solid ${theme.palette.custom.quaternary}`,
    borderTop: '1px solid #444444',
    display: 'flex',
    backgroundColor: '#B3B3B3',
  },
  closeButton: {
    borderRadius: 4,
    width: 50,
  },
  deleteButton: {
    backgroundColor: 'red',
  },
  deleteContainer: {
    padding: '25px',
    display:'flex',
    flexDirection: 'column',
    gap: 25,
  },
  header: {},
}))

export function DeleteActionPanel(props){
  const classes = useStyles()

  return (
    <>
      <ActionPanelHeader
        title={props.title}
        waiting={props.waiting}// fetchStatus === 'waiting'}
        handleClose={props.handleClose}
      />
      <div className={classes.deleteContainer}>
        <DeleteButton
          disabled={props.rowsSelected === 0}
          color='secondary'
          onClick={props.handleDelete}
        >
          {`Delete ${props.rowsSelected} ${props.type}`}
        </DeleteButton>
      </div>
    </>
  )
}


export const ActionPanelHeader = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.drawerHeader}>
      <div style={{flex: 1}}></div>
      <div style={{flexGrow: 2, textAlign: 'center', margin: 'auto'}}>
        <Typography style={{color: 'black', fontWeight: 'bold'}}>
          {props.title}
        </Typography>
      </div>
      <div style={{flex: 1}}>
        <Tooltip
          title='Close Filter Panel'
          placement='top'
          arrow
          style={{float: 'right'}}
        >
          <div>
            <IconButton
              className={classes.closeButton}
              onClick={props.handleClose}
              disabled={props.waiting}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </Tooltip>
      </div>
    </div>
  )
}

export default function ActionPanel(props) {
  const classes = useStyles()

  return (
    <Drawer
      classes={{paper: classes.paper}}
      open={props.open}
      onClose={props.handleClose}
      anchor='right'
      variant='persistent'
    >
      <div>
        {props.children}
      </div>
    </Drawer>
  )
}
