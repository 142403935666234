import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import { RespComponent } from './RespComponent'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  textField: {
    textAlign: 'left',
    '& .MuiOutlinedInput-root': {
      height: 45,
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.custom.outlinedTextField.focused.border,
        borderWidth: '1px',
      },
    },
    '& .MuiInputLabel-root': {
      top: -5,
      color: theme.palette.custom.outlinedTextField.unfocused.label,
      '&.Mui-focused': {
        color: theme.palette.custom.outlinedTextField.focused.label,
      },
    },
    '& .MuiInputLabel-shrink':{
      top: 0,
      color: theme.palette.custom.outlinedTextField.unfocused.label,
      '&.Mui-disabled': {
        color: theme.palette.custom.outlinedTextField.disabled.label,
      },
    },
  },
  disabledLabel: {
    color: `${theme.palette.custom.outlinedTextField.disabled.label} !important`,
  },
  checkboxText: {
    color: `${theme.palette.type === 'dark' ? 'white' : 'black'}`,
    display:'flex',
    alignItems: 'center',
    width: '100%',
    marginTop: 2,
  },
  checkboxTextColor: {
    color: `${theme.palette.type === 'dark' ? '#FFFFFF' : '#000000'}`,
  },
  checkboxTextColorDisabled: {
    color: `${theme.palette.type === 'dark' ? '#FFFFFF42' : '#00000042'}`,
  },
  checkboxButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: 15,
  },
  checkContainer: {
    width: 20,
    display: 'flex',
    alignItems: 'center',
  },
  checkboxButton: {
    borderRadius: 3,
    width:'100%',
    height: '100%',
    textTransform: 'capitalize',
  },
  disabledCheckboxIcon: {
    color: `${theme.palette.secondary}42 !important`,
  },
}))

const OutlinedTextField = (props) => {
  const classes = useStyles()
  return (
    <TextField
      {...props}
      InputLabelProps={{ shrink: true }}
      variant='outlined'
      className={`${classes.textField} ${props.className}`}
      classes={{root: classes.textField}}
    />
  )
}

const OutlinedSelect = (props) => {
  const classes = useStyles()
  return (
    <TextField
      SelectProps={{ height: 45, display:'flex' }}
      {...props}
      select
      InputLabelProps={{ shrink: true }}
      variant='outlined'
      className={`${classes.textField} ${props.className}`}
      classes={{root: classes.textField}}
    >
      {props.children}
    </TextField>
  )
}

const GenericOutlinedField = (props) => {
  const classes = useStyles()
  return (
    <FormControl
      variant='outlined'
      className={`${classes.textField} ${props.className}`}
      style={{width: '100%'}}
    >
      <InputLabel
        shrink
        className={props.disabled ? classes.disabledLabel : null}
      >
        {props.label}
      </InputLabel>
      <OutlinedInput
        style={props.style}
        disabled={props.disabled}
        notched
        id='component-disabled'
        label={props.label}
        inputComponent={props.inputComponent}
      />
    </FormControl>
  )
}

const OutlinedCheckBox = (props) => {
  const classes = useStyles()
  const parseProps = () => {
    const {
      label,
      ...formattedProps
    } = props
    return formattedProps
  }

  const getCheck = () => {
    return (
      <Button
        className={classes.checkboxButton}
        onClick={props.onClick}
        disabled={props.disabled}
        color='secondary'
      >
        <div className={classes.checkboxButtonContainer}>
          <div className={classes.checkContainer}>
            {props.checked ?
              props.disabled ?
                <CheckBoxIcon className={classes.disabledCheckboxIcon}/> :<CheckBoxIcon/>:
              <CheckBoxOutlineBlankIcon style={{color: props.disabled ? 'rgba(0, 0, 0, 0.26)' : 'rgba(0, 0, 0, 0.54)'}}/>
            }
          </div>
          <Typography
            className={`${classes.checkboxText} ${props.disabled ? classes.checkboxTextColorDisabled : classes.checkboxTextColor}`}
          >
            {props.label}
          </Typography>
        </div>
      </Button>
    )
  }
  return (
    <GenericOutlinedField
      {...parseProps()}
      inputComponent={getCheck}
    />
  )
}

const RespField = (props) => {
  const parseProps = () => {
    const {
      onBlur,
      ...formattedProps
    } = props
    return formattedProps
  }

  return (
    <RespComponent
      childComponent={OutlinedTextField}
      type='text'
      onClick={props.onBlur}
      {...parseProps()}
    >
      {props.children}
    </RespComponent>
  )
}

const RespSelect = (props) => {
  const parseProps = () => {
    const {
      onChange,
      ...formattedProps
    } = props
    return formattedProps
  }

  return (
    <RespComponent
      childComponent={OutlinedSelect}
      type='selects'
      onClick={props.onChange}
      {...parseProps()}
    >
      {props.children}
    </RespComponent>
  )
}

export { OutlinedTextField, OutlinedSelect, OutlinedCheckBox, GenericOutlinedField, RespField, RespSelect }