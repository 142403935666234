const DEFAULT_GROUP_FIELDS = () => {
  return {
    missionPackId: '',
    genJoinCode: true,
    groupName: '',
    licenseType: 'Floating',
    joinCode: '',
    lmsId: 'none',
    lmsClassId: '',
    lmsClassName: '',
  }
}

const DEFAULT_MEMBER_FIELDS = () => {
  return {
    name: '',
    email: '',
    role: 'Member',
    consumesLicense: true,
  }
}

const ROLES = [
  'Member',
  'Assistant',
  'Owner',
]

export { DEFAULT_GROUP_FIELDS, DEFAULT_MEMBER_FIELDS, ROLES }