import React from 'react'
import { CircularProgress, Typography } from '@material-ui/core'

import { useLogin } from '../../../common/LoginContext'
import { getSim } from '../../../common/utils'
import { FiriaGreenButton } from '../../../common/buttons'


const getAuthorizationState = async (user, lmsId) => {
  try {
    const resp = await getSim(`lms/${lmsId}/authorization/3-leg/status?role=teacher`, user)
    if (resp.status === 200) {
      return await resp.json()
    }
  } catch (err) {
    //
  }
}

let setAuthorizingState = () => {}
const getAuthorizationURL = async (user, lmsId) => {
  try {
    setAuthorizingState(new Date())
    const resp = await getSim(`lms/${lmsId}/authorization/3-leg?role=teacher`, user)
    const data = await resp.text()
    if (data && resp.status === 200) {
      window.open(data, '_blank')
    }
  } catch (err) {
    // empty
  }
}

function AwaitingResponseBasePanel({ children }) {
  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height:'100%'}}>
      {children}
      <div style={{height: 40}}></div>
      <CircularProgress style={{color: '#4CAF5066'}} size={55}/>
      <div style={{height: 40}}></div>
    </div>
  )
}

function VerifyAuthorizationPanel() {
  return (
    <AwaitingResponseBasePanel>
      <Typography>
        {'Verifying Authorization'}
      </Typography>
    </AwaitingResponseBasePanel>
  )
}

function AwaitingAuthorizationPanel() {
  return (
    <AwaitingResponseBasePanel>
      <Typography>
        {'Awaiting Authorization'}
      </Typography>
    </AwaitingResponseBasePanel>
  )
}

function AuthorizeConfirmationPanel({ errorMsg, lmsId }) {
  const [loginState] = useLogin()

  return (
    <div style={{height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
      {errorMsg ? <>
        <Typography>
          {'An error occurred during authorization!'}
        </Typography>
        <Typography>
          {'Please try again.'}
        </Typography>
      </> :
        <Typography>
          {'Google Classroom authorization is required to proceed.'}
        </Typography>
      }

      <div style={{height: 20}}></div>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <div style={{display: 'flex', alignItems:'center'}}>
          <FiriaGreenButton
            variant='contained'
            onClick={() => getAuthorizationURL(loginState?.user, lmsId)}
          >
            {'Authorize'}
          </FiriaGreenButton>
        </div>
      </div>
    </div>
  )
}

function LMS3LegAuthorizationPanel({ authorizationRequired, setAuthorizationRequired, authorizationSuccessCb, lmsId }) {
  const [loginState] = useLogin()
  const [authorizing, setAuthorizing] = React.useState(false)
  const [fetchingClasses, setFetchingClasses] = React.useState(false)
  const [authStatus, setAuthStatus] = React.useState(null)
  setAuthorizingState = (e) => {
    setAuthorizing(e)
    setAuthStatus(null)
  }

  const authorizationStatusPollerRef = React.useRef(null)
  const clearAuthorizationStatusPoller = () => {
    clearInterval(authorizationStatusPollerRef.current)
    authorizationStatusPollerRef.current = null
  }

  React.useEffect(() => {
    return () => {
      clearAuthorizationStatusPoller()
      setAuthStatus(null)
    }
  }, [])

  React.useEffect(() => {
    if (authorizing) {
      setAuthStatus({started: new Date()})
      authorizationStatusPollerRef.current = setInterval(async () => {
        const authState = await getAuthorizationState(loginState?.user, lmsId)
        if (authorizationStatusPollerRef.current) {
          setAuthStatus(authState)
        }
        if (!(new Date(authState?.started) >= authorizing)) {
          return
        }
        if (authState?.completed) {
          clearAuthorizationStatusPoller()
        }
        if (authState?.succeeded) {
          authorizationSuccessCb()
        }
      }, 500)
    } else {
      clearAuthorizationStatusPoller()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizing])

  React.useEffect(() => {
    if (!authorizationRequired) {
      clearAuthorizationStatusPoller()
      setAuthorizationRequired(false)
      setAuthorizing(false)
      setFetchingClasses(false)
    } else {
      setAuthStatus(null)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizationRequired])

  const LOADING_SPINNER_OPEN = fetchingClasses
  const AUTHORIZATION_REQUIRED_PANEL_OPEN = Boolean((authorizationRequired && !authStatus) || authStatus?.error)
  const AWAITING_AUTH_PANEL_OPEN = authorizing && !authStatus?.completed && !AUTHORIZATION_REQUIRED_PANEL_OPEN
  return (
    <div>
      {AUTHORIZATION_REQUIRED_PANEL_OPEN? <AuthorizeConfirmationPanel errorMsg={authStatus?.error} lmsId={lmsId}/> : null}
      {LOADING_SPINNER_OPEN ? <VerifyAuthorizationPanel/>:null}
      {AWAITING_AUTH_PANEL_OPEN ? <AwaitingAuthorizationPanel/>:null}
    </div>
  )
}

export { LMS3LegAuthorizationPanel }