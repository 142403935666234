import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { LoginActions, useLogin } from './LoginContext'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme => ({
  contentContainer: {
    textAlign: 'center',
  },
  logoBanner: {
    width: '50%',
    padding: theme.spacing(1),
  },
  formFields: {
    maxWidth: '25em',
    width: '100%',
  },
  optionFieldsContainer: {
    maxWidth: '25em',
    width: '100%',
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  optionField: {
    fontSize: theme.typography.caption.fontSize,
    textTransform: 'none',
    verticalAlign: 'text-top',
    paddingTop: theme.spacing(1),
  },
  signInButtonContainer: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  forgotPasswordButton: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  signInButton: {
    maxWidth: '25em',
    width: '100%',
    backgroundColor: '#db4437',
  },
  dividerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dividers: {
    maxWidth: '10em',
    width: '100%',
    flexShrink: 'inherit',
  },
  loginProviderList: {
    margin: '1em 0',
    padding: '0 24px',
  },
  defaultCursor: {
    cursor: 'default',
  },
  footer: {
    backgroundColor: theme.palette.background.default,
    maxWidth: '100%',
    height: '5em',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backdrop: {
    position: 'absolute',
    backdropFilter: 'blur(5px)',
    backgroundColor:'rgba(0,0,0,0.7)',
  },
  dialogRoot: {
    height: 'calc(100vh - 50px)',
    marginTop: '50px',
  },
  unreadyPaper: {
    background: 'transparent',
    boxShadow: '0px 0px 0px',
  },
}))

const HideContentDialog = (props) => {
  const classes = useStyles()

  const parseProps = () => {
    const {
      onClose,
      disableBackdropClick,
      disableEscapeKeyDown,
      ...formattedProps
    } = props
    return formattedProps
  }

  // Fixes 'Failed prop type: The prop `disableBackdropClick` of `ForwardRef(Modal)` is deprecated.'
  const handleClose = (event, reason) => {
    if (props.disableBackdropClick && reason === 'backdropClick') {
      return false
    }

    if (props.disableEscapeKeyDown && reason === 'escapeKeyDown') {
      return false
    }

    if (typeof onClose === 'function') {
      props.onClose()
    }
  }

  return (
    <Dialog
      classes={{root: classes.dialogRoot}}
      BackdropProps={{
        classes: { root: classes.backdrop, invisible: classes.backdrop },
      }}
      {...parseProps()}
      onClose={handleClose}
    >
      {props.children}
    </Dialog>
  )
}

export const LoginUnready = () => {
  const classes = useStyles()
  return (
    <HideContentDialog
      disableBackdropClick
      disableEscapeKeyDown
      open={true}
      classes={{paper: classes.unreadyPaper}}
    >
      <div style={{width: 200, height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <CircularProgress style={{color: '#4CAF50'}}/>
      </div>
    </HideContentDialog>
  )
}

export const LogoutComponent = ({ open, onClose }) => {
  const classes = useStyles()
  const [loginState, loginDispatch] = useLogin()
  const userEmail = loginState?.user?.email
  const preventDefault = event => event.preventDefault()

  return (
    <Dialog open={open}>
      <DialogTitle className={classes.contentContainer}>
        <div>
          <Typography variant='h5'>Log Out</Typography>
        </div>
      </DialogTitle>
      <DialogContent className={classes.contentContainer}>
        <DialogContentText>Logged in as <Link color='inherit' underline='always' href={'mailto:' + userEmail} onClick={preventDefault}>{userEmail}</Link></DialogContentText>
        <DialogContentText>Are you sure you want to log out?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            !!onClose && onClose()
          }}
          color='primary'
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            loginDispatch({ type: LoginActions.LOGOUT })
            !!onClose && onClose()
          }}
        >
          Log Out
        </Button>
      </DialogActions>
    </Dialog>
  )
}