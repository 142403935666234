import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Typography } from '@material-ui/core'

import { OutlinedTextField, OutlinedCheckBox, RespField } from '../../common/fields'
import { RespButton, DeleteButton, FiriaGreenButton } from '../../common/buttons'
import { ActionPanelHeader } from '../shared-components/ActionPanel'
import { postSim } from '../../common/utils'
import { useLogin } from '../../common/LoginContext'

import { DEFAULT_GROUP_FIELDS } from '../shared-components/sharedObjects'
import { CURATED_ASSIGNMENTS_BY_MISSION_PACK_ID } from './generate-assignments-dialog/curated-assignments'
import { useNavigate } from 'react-router-dom'


const useStyles = makeStyles(theme => ({
  wideField: {
    width: '100%',
  },
  actionPanelContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    padding: '5px',
    paddingTop: '25px',
    overflowY: 'auto',
    height: 'calc(100vh - 106px)',
  },
}))

function EditGroupActionPanel(props) {
  const classes = useStyles()
  const navigate = useNavigate()
  const [groupFields, setGroupFields] = React.useState(DEFAULT_GROUP_FIELDS())
  const [fetchStatus, setFetchStatus] = React.useState(null)
  const [allowJoinCodeStatus, setAllowJoinCodeStatus] = React.useState(null)
  const [joinCodeFetchStatus, setJoinCodeFetchStatus] = React.useState(null)
  const classData = props.classData
  const disabled =
  classData.groupName === '' ||
  allowJoinCodeStatus === 'waiting' ||
  joinCodeFetchStatus === 'waiting' ||
  fetchStatus === 'waiting'
  const [loginState] = useLogin()

  React.useEffect(() => {
    const groupFields = DEFAULT_GROUP_FIELDS()
    groupFields.missionPackId = classData.missionPackId
    groupFields.genJoinCode = classData.joinActive === '' ? false : classData.joinActive
    groupFields.groupName = classData.groupName
    groupFields.joinCode = classData.joinCode
    groupFields.licenseType = classData.licenseType
    setGroupFields(groupFields)
  }, [classData])

  const setFieldChange = (key, value) => {
    var copyOfFields = {...groupFields}
    copyOfFields[key] = value
    setGroupFields(copyOfFields)
  }

  const saveChange = async (name, joinActive) => {
    return await postSim(`groups/${props.groupId}`, loginState?.user, {
      name,
      joinActive,
    })
  }

  const saveChangeSuccess = () => {
    props.getGroups()
  }

  const handleUpdateJoinCode = () => {
    return postSim(`groups/${props.groupId}/joinCode`, loginState?.user, {})
  }

  const handleUpdateJoinCodeSuccess = async () => {
    await props.getGroups()
  }

  function openLessonAccessControlPage() {
    navigate('lesson-access-control')
  }

  return (
    <>
      <ActionPanelHeader
        title={props.title}
        waiting={disabled}
        handleClose={props.handleClose}
      />
      <div className={classes.actionPanelContent}>
        <RespField
          label={'Class Name'}
          onChange={ev=>setFieldChange('groupName', ev.target.value)}
          disabled={disabled}
          value={groupFields.groupName}
          onBlur={(ev) => {
            if (ev.target.value === '') {
              setFieldChange('groupName', classData.groupName)
            } else if (classData.groupName !== ev.target.value) {
              return saveChange(groupFields.groupName, groupFields.genJoinCode)
            }
            return true
          }}
          symmetrical={true}
          style={{width: '100%'}}
          successCb={saveChangeSuccess}
          status={fetchStatus}
          setStatus={setFetchStatus}
        />
        <RespButton
          buttonComponent={OutlinedCheckBox}
          onClick={(ev)=>{
            setFieldChange('genJoinCode', !groupFields.genJoinCode)
            return saveChange(groupFields.groupName, !groupFields.genJoinCode)
          }}
          label='Allow Classroom Join'
          checked={groupFields.genJoinCode}
          successCb={props.getGroups}
          status={allowJoinCodeStatus}
          setStatus={setAllowJoinCodeStatus}
          disabled={disabled}
          symmetrical={true}
        />
        <Typography variant='body2'>
          {`Join Code: ${groupFields.joinCode}`}
        </Typography>
        <RespButton
          color='secondary'
          buttonComponent={FiriaGreenButton}
          onClick={handleUpdateJoinCode}
          successCb={handleUpdateJoinCodeSuccess}
          status={joinCodeFetchStatus}
          setStatus={setJoinCodeFetchStatus}
          disabled={disabled}
          symmetrical={true}
        >
          {'Get New Join Code'}
        </RespButton>
        {props.classData.lmsClassId ?
          <>
            <Typography variant='body2'>
              {`LMS: ${props.classData.lmsId.charAt(0).toUpperCase() + props.classData.lmsId.slice(1)}`}
            </Typography>
            <Typography variant='body2'>
              {`LMS Class Name: ${props.classData.lmsClassName}`}
            </Typography>
          </>:
          null
        }
        <div style={{paddingLeft: 29, paddingRight: 29}}>
          {props.classData.lmsClassId ?
            <Button
              className={classes.wideField}
              onClick={props.openGenerateAssignmentsDialog}
              style={{marginBottom: 20}}
              disabled={CURATED_ASSIGNMENTS_BY_MISSION_PACK_ID[props.classData.missionPackId] === undefined}
              variant='outlined'
            >
              {'Export CodeSpace Assignments to LMS'}
            </Button>:
            null}
          <OutlinedTextField
            className={classes.wideField}
            style={{paddingBottom: '20px'}}
            label={'License Type'}
            onChange={()=>{}}
            value={groupFields.licenseType}
            disabled
          />
          <OutlinedTextField
            className={classes.wideField}
            style={{paddingBottom: '20px'}}
            label={'Curriculum'}
            onChange={()=>{}}
            value={props.packList.find(pack => pack.id === groupFields.missionPackId)?.title ?? ''}
            disabled
          />
          {groupFields.missionPackId !== 'HWN' &&
          <div style={{backgroundColor: 'rgba(76, 175, 80, 0.7)', marginBottom: 20, borderRadius: 3}}>
            <Button
              className={classes.wideField}
              onClick={openLessonAccessControlPage}
              variant='outlined'
            >
              {'Open Lesson Access Controller'}
            </Button>
          </div>}
          <DeleteButton
            className={classes.wideField}
            variant='contained'
            onClick={props.handleDeleteGroups}
            disabled={disabled}
          >
            {'Delete Class'}
          </DeleteButton>
        </div>
      </div>
    </>
  )
}

export { EditGroupActionPanel }