function handleMultipleStatuses(status1, status2) {
  if (status1 === 'error' || status2 === 'error'){
    return 'error'
  } else if (status1 === 'waiting' || status2 === 'waiting'){
    return 'waiting'
  } else {
    return 'success'
  }
}

export { handleMultipleStatuses }