import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Tooltip, Typography, Checkbox, Avatar, Grid } from '@material-ui/core'
import {
  RadioButtonUnchecked,
  Block,
  LockOpen,
  Lock,
  Person as PersonIcon,
  Group as GroupIcon,
  HelpOutline as HelpOutlineIcon,
} from '@material-ui/icons'

import { RespButton } from '../../common/buttons'
import { lessonContentDomain, AVATAR_SOURCE } from '../../common/utils'

import PanelTemplate from '../shared-components/PanelTemplate'
import { useGroups } from '../../contexts/GroupContext'
import { useSnackbar } from 'notistack'
import { LessonAccessProvider, useLessonAccess, LESSON_ACCESS_TYPES, lamControl } from './LessonAccessContext'
import { UserSelectionDialog } from './UserSelectionDialog'
import { OverwriteDialog } from './OverwriteDialog'
import { displayLessonAccessInfoSnackbar } from './display-lesson-access-info-snackbar'

const border = '1px solid rgba(96,96,96,0.3)'

const useStyles = makeStyles((theme) => {
  return {
    mainPanelContent: {
      width: '100%',
      height: '100%',
      display: 'flex',
      backgroundColor: 'rgba(0,0,0,0.2)',
      borderTop: '1px solid black',
    },
    mainPanelContainer: {
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.primary.main,
    },
    bodyContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      // padding: '10px 20px',
      backgroundColor: 'rgba(255,255,255,0.9)',
    },
    bodyContent: {
      backgroundColor: theme.palette.primary.main,
      // border: '1px solid rgba(0,0,0,0.4)',
      minWidth: 1000,
      width: '50%',
    },
    detailContainer: {
      backgroundColor: 'white',
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    footerContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent:'space-between',
      borderTop: border,
      padding: '10px 20px',
      height: 60,
      gap: 10,
    },
    rowButtonContainer: {
      height: '100%',
      width: '100%',
    },
    rowButton: {
      textTransform: 'none',
      borderRadius: 3,
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%',
      height: '100%',
      padding: 0,
    },
    avatar: {
      borderRadius: 3,
      height: 50,
      width: 50,
      margin: '3px 6px',
    },
    rowTextContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
    gridContainer: {
      height: '100%',
      overflowY: 'overlay',
      overflowX: 'hidden',
    },
    grid: {
      padding: '10px 20px',
    },
    detailHeaderContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      // backgroundColor: 'rgba(0,0,0,0.05)',
      borderBottom: border,
      padding: '10px 20px',
      gap: 10,
    },
    detailHeaderUserSelectContainer:{
      borderRadius: 3,
      backgroundColor: 'white',
      width: 'calc(50% - 8px)',
    },
    missionListSelectorContainer: {
      display: 'flex',
      alignItems:'center',
      gap: 10,
    },
    tooltip: {
      maxWidth: 900,
      backgroundColor: 'rgba(245, 245, 245,1)',
      border: '1px black solid',
      color: 'black',
    },
    controlSelectorContainer: {
      borderRight: border,
      height: '100%',
      padding: '10px 20px',
      gap: 3,
      display: 'flex',
      flexDirection: 'column',
      alignItems:'flex-end',
    },
    controlSelectorContent: {
      gap: 3,
      display: 'flex',
      flexDirection: 'column',
      alignItems:'flex-start',
      border: border,
      borderRadius: 3,
      backgroundColor: 'white',
      padding: '10px 20px',
    },
  }
})

function MissionListSelectorButtons() {
  const classes = useStyles()
  const {
    allLessonItemsSelected,
    changesMadeToSelectedMissionPackIds,
    unlockerSelected,
  } = useLessonAccess()
  const {
    selectAllLessonItems,
    deselectAllLessonItems,
    discardLessonItemChanges,
  } = lamControl

  function onLessonSelectionClick() {
    if (allLessonItemsSelected) {
      deselectAllLessonItems()
    } else {
      selectAllLessonItems()
    }
  }

  return (
    <div className={classes.missionListSelectorContainer}>
      <div style={{backgroundColor: 'white'}}>
        <Button
          onClick={discardLessonItemChanges}
          disabled={!changesMadeToSelectedMissionPackIds}
          variant='contained'
          disableElevation
          style={{border: '1px solid rgba(0,0,0,0.2)'}}
        >
          {'Discard Changes'}
        </Button>
      </div>
      {unlockerSelected &&
        <div style={{backgroundColor: 'white'}}>
          <Button
            onClick={onLessonSelectionClick}
            variant='outlined'
            style={{width: 139}}
          >
            {allLessonItemsSelected ? 'Deselect All' : 'Select All'}
          </Button>
        </div>
      }
    </div>
  )
}

function HelpPanelBulletLine({ bullet, text, selected }) {
  return (
    <div style={{display: 'flex', gap: 5, alignItems: 'start', paddingBottom: 10}}>
      <Typography style={{fontWeight: 'bold', whiteSpace: 'nowrap'}} nowrap>{`• ${bullet}${selected ? ' (selected)': ''}:`}</Typography>
      <Typography>{text}</Typography>
    </div>
  )
}

function HelpPanel() {
  const { singleUserMode, unlockerSelected } = useLessonAccess()
  return (
    <div style={{padding: '10px 20px'}}>
      <center>
        <Typography style={{paddingBottom: 10, paddingTop: 5}} variant='h4'>{'Lesson Access Control'}</Typography>
        <Typography style={{paddingBottom: 10}}>{'Manage which missions your students can access.'}</Typography>
      </center>
      <Typography style={{paddingBottom: 10, paddingTop: 10 }} variant='h5'>{'Progress Unlocker vs. Progression Gate'}</Typography>
      <HelpPanelBulletLine
        bullet='Progress Unlocker'
        text='Lets students skip a mission without affecting their progress.'
        selected={unlockerSelected}
      />
      <HelpPanelBulletLine
        bullet='Progression Gate'
        text='Stops students from moving beyond a certain mission.'
        selected={!unlockerSelected}
      />
      <Typography style={{paddingBottom: 10, paddingTop: 20}} variant='h5'>{'Class Controller vs. Student Controller'}</Typography>
      <HelpPanelBulletLine
        bullet='Class Controller'
        text='Applies settings to the whole class.'
        selected={!singleUserMode}
      />
      <HelpPanelBulletLine
        bullet='Student Controller'
        text={`Applies settings to an individual student, overriding class settings.${!singleUserMode ?' Accessible by selecting the student from the class list.':''}`}
        selected={singleUserMode}
      />
    </div>
  )
}

function DetailHeader() {
  const classes = useStyles()
  const { singleUserMode, unlockerSelected } = useLessonAccess()

  return (
    <div className={classes.detailHeaderContainer}>
      {singleUserMode ? <PersonIcon/> : <GroupIcon/>}
      <Typography style={{paddingLeft: 5}} variant='h5'>{`${singleUserMode ? 'Student':'Class'} ${unlockerSelected ? 'Progress Unlocker':'Progression Gate'}`}</Typography>
      <Tooltip title={<HelpPanel />} placement='bottom' arrow classes={{ tooltip: classes.tooltip }}>
        <HelpOutlineIcon style={{fontSize: 20, color: 'gray'}} />
      </Tooltip>
      {/* {<HelpOutlineIcon style={{color: 'rgba(0,0,0,0.7)', fontSize: 20}}/>} */}
    </div>
  )
}

function MissionRow({ id, avatar, title, idx }) {
  const classes = useStyles()
  const { selectedMissionPackIds, unlockerSelected,  selectableLessonItems } = useLessonAccess()
  const { toggleSelectedMissionContentRow } = lamControl

  function handleClick() {
    toggleSelectedMissionContentRow(id)
  }

  // unlockerSelected ? 'rgba(0,128,0, 0.5)' : 'rgba(255,0,0, 0.5)'
  const selected = selectedMissionPackIds.includes(id)

  let backgroundColor = 'rgba(134, 227, 134, 0.3)'
  let borderColor = 'rgba(0,128,0, 0.7)'
  let checkedIcon = <LockOpen style={{color: 'rgba(0,128,0'}}/>
  let icon = <Lock />
  let rowLockedByPreviousBlockerRow = false
  if (!unlockerSelected) {
    if (selectedMissionPackIds.length > 0) {
      const selectedMissionPackId = selectedMissionPackIds[0]
      rowLockedByPreviousBlockerRow =  selectableLessonItems.indexOf(selectedMissionPackId) < selectableLessonItems.indexOf(id)
    }
    backgroundColor = 'rgba(255,0,0, 0.1)'
    borderColor = 'rgba(150, 20, 20, 1)'
    checkedIcon = <Block style={{color: rowLockedByPreviousBlockerRow ? 'rgba(0,0,0,0.8)':'#e91414'}}/>
    icon = <RadioButtonUnchecked />
  }

  if (!selected) {
    backgroundColor = 'rgba(0,0,0,0.03)'
    borderColor = 'rgba(0,0,0,0.2)'
  }

  if (rowLockedByPreviousBlockerRow) {
    backgroundColor = 'rgba(255,0,0, 0.1)'
    borderColor = 'rgba(150, 20, 20, 1)'
  }

  return (
    <Grid
      item
      key={id}
      md={6}
      sm={6}
      xs={12}>
      <div
        className={classes.rowButtonContainer}
        style={{backgroundColor: backgroundColor }}
      >
        <Button
          key={id}
          disableRipple={true}
          onClick={handleClick}
          className={classes.rowButton}
          style={{ border: `1px solid ${borderColor}` }}
        >
          <Avatar
            variant='square'
            src={lessonContentDomain + AVATAR_SOURCE + avatar}
            className={classes.avatar}
          />
          <Checkbox
            checked={selected || rowLockedByPreviousBlockerRow}
            checkedIcon={checkedIcon}
            icon={icon}
          />
          <div className={classes.rowTextContainer}>
            <Typography variant='caption'>{`Mission ${idx + 1}`}</Typography>
            <Typography style={{fontSize: 18}}>{`${title}`}</Typography>
          </div>
        </Button>
      </div>
    </Grid>
  )
}

function DetailBody() {
  const classes = useStyles()
  const { selectedGroupMissionPack } = useGroups()

  return (
    <div className={classes.gridContainer}>
      <Grid
        container
        spacing={1}
        alignContent='flex-start'
        className={classes.grid}
      >
        {selectedGroupMissionPack.missions.map((msn, idx) =>
          <MissionRow key={msn.id} idx={idx} {...msn} />
        )}
      </Grid>
    </div>
  )
}

function DetailFooter(){
  const classes = useStyles()
  const snacks = useSnackbar()
  const {
    postingCodeState,
    changesMadeToSelectedMissionPackIds,
    singleUserMode,
    membersAreOverwritable,
  } = useLessonAccess()
  const {
    toggleDetailSelectionDialogOpen,
    toggleOverwriteDialogOpen,
    onSingleCodesSuccess,
    setPostingCodeState,
    postSingleCode,
  } = lamControl

  function handleApplySingleLessonUnlockCodeChangesSuccess() {
    displayLessonAccessInfoSnackbar(snacks)
    snacks.enqueueSnackbar('Successfully applied changes.', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      autoHideDuration: 2000,
    })
    onSingleCodesSuccess()
  }

  return (
    <div className={classes.footerContainer}>
      <MissionListSelectorButtons />
      <div style={{display: 'flex', alignItems:'center', gap: 10}}>
        {!singleUserMode && membersAreOverwritable ?
          <div style={{backgroundColor: 'white'}}>
            <Button
              variant='contained'
              color='secondary'
              style={{border: '1px solid rgba(0,0,0,0.2)'}}
              disabled={!changesMadeToSelectedMissionPackIds}
              onClick={toggleOverwriteDialogOpen}>
              {'Apply Changes To Class'}
            </Button>
          </div>:
          <RespButton
            variant='contained'
            color='secondary'
            buttonComponent={Button}
            onClick={postSingleCode}
            successCb={handleApplySingleLessonUnlockCodeChangesSuccess}
            status={postingCodeState}
            setStatus={setPostingCodeState}
            disabled={!changesMadeToSelectedMissionPackIds}
            style={{border: '1px solid rgba(0,0,0,0.2)'}}
            disableElevation
          >
            {singleUserMode ? 'Apply Changes To Student' : 'Apply Changes To Class'}
          </RespButton>
        }
        {singleUserMode &&
          <div style={{backgroundColor: 'white'}}>
            <Button
              variant='outlined'
              onClick={toggleDetailSelectionDialogOpen}
              disabled={!changesMadeToSelectedMissionPackIds}
            >{'Apply To Multiple Students'}</Button>
          </div>
        }
      </div>
    </div>
  )
}

function UnlockControlDetail() {
  const classes = useStyles()

  return (
    <div className={classes.detailContainer}>
      <DetailHeader />
      <DetailBody />
      <DetailFooter />
      <UserSelectionDialog />
      <OverwriteDialog />
    </div>
  )
}

function UserProgressControlSelector() {
  const classes = useStyles()
  const { unlockerSelected, lockerSelected } = useLessonAccess()
  const { setSelectedLessonAccessType } = lamControl

  function handleClickedUnlocker() {
    setSelectedLessonAccessType(LESSON_ACCESS_TYPES.UNLOCKER)
  }

  function handleClickedLocker() {
    setSelectedLessonAccessType(LESSON_ACCESS_TYPES.LOCKER)
  }
  // 'rgba(0,128,0, 0.5)' : 'rgba(255,0,0, 0.5)'
  return (
    <div className={classes.controlSelectorContainer}>
      <div className={classes.controlSelectorContent}>
        <Typography variant='body2' style={{color: 'gray', fontWeight: 'bold', paddingBottom: 10}}>
          {'Lesson Access Control Options'}
        </Typography>
        <Button
          style={{
            border: `1px solid ${unlockerSelected ? 'rgba(0,128,0,1)':'rgba(0,0,0,0.2)'}`,
            backgroundColor: unlockerSelected ? 'rgba(0,128,0,0.7)':null,
            color: unlockerSelected ? 'white':'black',
            textTransform: 'none',
            width: '100%',
            display: 'flex',
            alignItems: 'start',
            padding: '10px 20px',
            marginBottom: 5,
          }}
          onClick={handleClickedUnlocker}
        >
          <Typography style={{fontWeight: unlockerSelected ? 'bold' : null}}>
            {'Progress Unlocker'}
          </Typography>
        </Button>
        <Button
          style={{
            border: `1px solid ${lockerSelected ? 'rgba(150, 20, 20, 1)':'rgba(0,0,0,0.2)'}`,
            backgroundColor: lockerSelected ? 'rgba(233, 20, 20, 1)':null,
            color: lockerSelected ? 'white':'black',
            textTransform: 'none',
            display: 'flex',
            alignItems: 'start',
            width: '100%',
            padding: '10px 20px',
          }}
          onClick={handleClickedLocker}
        >
          <Typography style={{fontWeight: lockerSelected ? 'bold' : null}}>
            {'Progression Gate'}
          </Typography>
        </Button>
      </div>
    </div>
  )
}

function LessonAccessControlPanelContent() {
  const classes = useStyles()

  return (
    <div className={classes.mainPanelContainer}>
      <div className={classes.mainPanelContent}>
        <div className={classes.bodyContainer}>
          <div style={{width: 'calc((100% - 1000px / 2))'}}>
            <UserProgressControlSelector />
          </div>
          <div className={classes.bodyContent}>
            <UnlockControlDetail />
          </div>
          <div style={{width: 'calc((100% - 1000px / 2))', borderLeft: border}}>
          </div>
        </div>
      </div>
    </div>
  )
}

function LessonAccessControlPage() {
  return (
    <LessonAccessProvider>
      <PanelTemplate
        mainPanelContent={
          <LessonAccessControlPanelContent />
        }
      />
    </LessonAccessProvider>
  )
}

export { LessonAccessControlPage }