import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Typography, Button } from '@material-ui/core'

import LoginPanel from '../common/LoginPanel'
import FiriaLabsBanner from '../common/firialabsbanner.png'
import { adjustColor } from '../common/utils'

import DashboardIcon from '../assets/dashimg.png'

const rightDrawerWidth = '23em'
const appHeaderHeight = '4em'

const useStyles = makeStyles(theme => ({
  appHeader: {
    backgroundColor: 'white',
    display:'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerItemLeft: {
    flex: 1,
    padding: '0 15px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  headerItemRight: {
    flex: 1,
    padding: '0 15px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  headerItemCenter: {
    flexGrow: 1,
    display:'flex',
    alignItems: 'center',
    textAlign: 'center',
  },
  headerImage: {
    alignSelf: 'center',
    height:'3em',
    marginTop: '0.5em',
    marginRight: 5,
    float: 'left',
  },
  headerImage2: {
    alignSelf: 'center',
    height:appHeaderHeight,
    margin: 'auto',
  },
  headerText: {
    color: 'black',
    display:'flex',
    alignItems: 'center',
    fontSize: '0.9em',
  },
  educatorResourcesButton: {
    backgroundColor: '#F4D038',
    color: 'black',
    whiteSpace: 'nowrap',
    minWidth: 'max-content',
    '&:hover': {
      backgroundColor: adjustColor('#F4D038', -10),
    },
  },
}))

function ClassDashboardSiteHeader() {
  const classes = useStyles()

  return (
    <Paper square elevation={5} className={classes.appHeader}>
      <div className={classes.headerItemLeft}>
        <div style={{verticalAlign: 'center', height: appHeaderHeight}}>
          <img className={classes.headerImage} alt={''} src={DashboardIcon}/>
          <Typography variant='h6' className={classes.headerText} style={{height: '100%'}}>
            {'Dashboard'}
          </Typography>
        </div>
        <div style={{flexGrow: 1}}></div>
      </div>
      <Button className={classes.educatorResourcesButton} variant='outlined' href={'https://learn.firialabs.com/curricula'}>
        {'Educator Resources'}
      </Button>
      <div className={classes.headerItemCenter}>
        <img className={classes.headerImage2} alt={''} src={FiriaLabsBanner}/>
      </div>
      <div style={{ width: 210 }}></div>
      <div className={classes.headerItemRight}>
        <div style={{height: appHeaderHeight}}>
          <div style={{float: 'right', display: 'flex', alignItems: 'center', height: '100%'}}>
            <LoginPanel />
          </div>
        </div>
        <div style={{flexGrow: 1}}></div>
      </div>
    </Paper>
  )
}

export { ClassDashboardSiteHeader, rightDrawerWidth, appHeaderHeight }
