import React from 'react'
import logger from './tools/tracking/logger'
import { downloadLogStore } from './tools/tracking/logStore'
import { Fab, Zoom } from '@material-ui/core'
import { Mail, GetApp, Close } from '@material-ui/icons'
import { smartlookInit } from './tools/tracking/smartlook'

const fabColor = '#1565C0'

const fabCommonStyles = {
  position:'absolute',
  right: 20,
  color: 'white',
  zIndex: 5000,
}

const ZoomFab = ({onClick, icon, title, visible, position}) => {
  return (
    <Zoom
      key={position}
      in={visible}
      timeout={100}
      unmountOnExit
    >
      <Fab onClick={onClick} style={{...fabCommonStyles, bottom: 20 + position*60, backgroundColor: fabColor}} color='primary' variant='extended'>
        {icon}
        {title}
      </Fab>
    </Zoom>
  )
}

const CustomerSupportFab = () => {
  const [fabsOpen, setFabsOpen] = React.useState(false)
  const handleFabsClose = () => {
    setFabsOpen(false)
  }

  const handleFabsOpen = () => {
    setFabsOpen(true)
  }

  window.onerror = (msg) => {
    logger.error(msg)
  }

  React.useEffect(() => {
    var firstClick = true
    const onOpenFabClick = () => {
      if (!firstClick) {
        setFabsOpen(false)
      }
      firstClick = false
    }
    if (fabsOpen){
      window.addEventListener('click', onOpenFabClick)
      return () => {
        window.removeEventListener('click', onOpenFabClick)
      }
    }
  }, [fabsOpen])

  React.useEffect(() => {
    smartlookInit()
  }, [])

  return (
    <>
      {fabsOpen ?
        <>
          <ZoomFab
            onClick={downloadLogStore}
            icon={<GetApp />}
            title={'Download Session Logs'}
            position={2}
            visible={fabsOpen}
          />
          <ZoomFab
            onClick={() => {
              window.open('mailto:support@firia.com')
            }}
            icon={<Mail />}
            title={'Contact Support'}
            position={1}
            visible={fabsOpen}
          />
          <Fab onClick={handleFabsClose} style={{...fabCommonStyles, bottom: 20, backgroundColor: '#C62828'}} size='small'>
            <Close />
          </Fab>
        </>:
        <Fab onClick={handleFabsOpen} style={{...fabCommonStyles, bottom: 20, backgroundColor: fabColor}} size='small'>
          <div style={{fontSize: 24}}>?</div>
        </Fab> }
    </>
  )
}

export default CustomerSupportFab