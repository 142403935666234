import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import CheckIcon from '@material-ui/icons/Check'
import ErrorIcon from '@material-ui/icons/Error'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import CircularProgress from '@material-ui/core/CircularProgress'
import Fade from '@material-ui/core/Fade'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FETCH_RESP } from './utils'

const errorColor = '#D32F2F'

const useStyles = makeStyles(theme => ({
  centered: {
    display:'flex',
    alignItems:'center',
    justifyContent: 'center',
  },
  progress: {
    color: theme.palette.type === 'dark' ? 'grey' : 'black',
  },
  errorBackground: {
    color: theme.palette.type === 'dark' ? 'white' : 'black',
  },
  error: {
    color: errorColor,
    position:'absolute',
  },
  success: {
    color: '#4CAF50',
  },
  tooltip: {
    top: '7px',
    backgroundColor: errorColor,
  },
  arrow: {
    color: errorColor,
  },
}))

const RespComponent = (props) => {
  const classes = useStyles()
  const status = props.status
  const [tooltipOpen, setTooltipOpen] = React.useState(false)
  const [checkVisible, setCheckVisible] = React.useState(false)
  const isMounted = React.useRef(false)
  const errorMsg = React.useRef('')
  const tooltipTimeout = React.useRef(null)
  const checkTimeout = React.useRef(null)

  const parseProps = () => {
    const {
      status,
      setStatus,
      onClick,
      successCb,
      placement,
      disabled,
      childComponent,
      type,
      resetStatus,
      symmetrical,
      leftIndicator,
      ...formattedProps
    } = props
    return formattedProps
  }

  const getErrorMsg = (msg) => {
    switch (msg) {
      case FETCH_RESP.ORDER_ALREADY_EXISTS:
        return 'Order number already exists!'
      case FETCH_RESP.FAILED_TO_TRANSLATE_SKU:
        return 'Failed to translate licenses from SKU!'
      case FETCH_RESP.LICENSE_TERM_FORMAT_INVALID:
        return 'License term format is invalid!'
      case FETCH_RESP.ORDER_NUMBER_INVALID:
        return 'Order cannot be found!'
      case FETCH_RESP.REQUESTING_USER_OWNS_ORDER:
        return 'You\'ve already claimed this order!'
      case FETCH_RESP.OTHER_USER_OWNS_ORDER:
        return 'This order has already been claimed!'
      case FETCH_RESP.GROUP_ALREADY_EXISTS:
        return 'This class has already been created!'
      case FETCH_RESP.GROUP_MEMBER_ALREADY_EXISTS:
        return 'This student has already been added!'
      case FETCH_RESP.ASSIGNED_LICENSE_FULL:
        return 'Your assigned licences are full!'
      case FETCH_RESP.LICENSE_DOES_NOT_EXIST:
        return 'The submitted license couldn\'t be found!'
      case FETCH_RESP.USER_DOES_NOT_EXIST:
        return 'The submitted user couldn\'t be found!'
      case FETCH_RESP.USER_ALREADY_OWNS_LICENSE:
        return 'The submitted user already owns this license!'
      case FETCH_RESP.USER_REACHED_REGISTRATION_LIMIT:
        return 'The user is already registered for this competition!'
      case FETCH_RESP.GROUP_OWNER_GROUP_NAME_EXISTS:
        return 'You\'ve already got a class with the same name! Please use a unique name.'
      default:
        return 'An unexpected error has occurred.'
    }
  }

  const handleAction = async (ev) => {
    props.setStatus('waiting')
    const resp = await props.onClick(ev)
    if (!resp) {
      props.setStatus('error')
    }

    // If the component has manually returned true, it means we skipped the fetch
    if (resp === true){
      props.setStatus('success')
      return
    }

    var msg = null
    try {
      msg = (await resp.json()).message
    } catch {
    }
    if (resp.status === 200){
      setCheckVisible(true)
      props.setStatus('success')
      props.successCb(msg)
      clearTimeout(checkTimeout.current)
      checkTimeout.current = setTimeout(()=>{
        if (isMounted.current){
          setCheckVisible(false)
        }
      }, 1000)
    } else {
      errorMsg.current = getErrorMsg(msg)
      setTooltipOpen(true)
      clearTimeout(tooltipTimeout.current)
      tooltipTimeout.current = setTimeout(()=>{
        if (isMounted.current){
          setTooltipOpen(false)
        }
      }, 3000)
      props.setStatus('error')
    }
  }

  const onMouseEnter = () => {
    setTooltipOpen(true)
    clearTimeout(tooltipTimeout.current)
  }

  const onMouseLeave = () => {
    setTooltipOpen(false)
    clearTimeout(tooltipTimeout.current)
  }

  const isDisabled = () => {
    const waiting = status === 'waiting'
    if (props.disabled !== undefined){
      return props.disabled || waiting
    }
    return waiting
  }

  React.useEffect(()=>{
    isMounted.current = true
    return ()=>{
      isMounted.current = false
    }
  } ,[])

  const resetStatus = props.resetStatus
  React.useEffect(() => {
    if (resetStatus && isMounted.current){
      props.setStatus(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetStatus])

  return (
    <div style={{display: 'flex', alignItems:'center', gap: 5, justifyContent: props.symmetrical ? 'space-around':null}}>
      {
        props.symmetrical && props.leftIndicator ?
          <div style={{width: '30px'}}></div>:
          null
      }
      {props.leftIndicator ?
        <ActionComponent
          type={props.type}
          component={props.childComponent}
          handleAction={handleAction}
          style={{width: props.symmetrical ? '100%' : null}}
          disabled={isDisabled()}
          {...parseProps()}
        >
          {props.children}
        </ActionComponent>:
        null
      }
      <div className={classes.centered} style={{width: '30px'}}>
        {props.status === 'success' ?
          <Fade
            in={checkVisible}
            timeout={{enter: 0, exit: 800}}
          >
            <CheckIcon className={classes.success}/>
          </Fade>:
          null
        }
        {props.status === 'error' ?
          <Tooltip
            leaveDelay={500}
            title={
              <Typography
                variant='body2'
              >
                {errorMsg.current}
              </Typography>
            }
            arrow
            placement={'top'}
            open={tooltipOpen && errorMsg.current !== ''}
            classes={{tooltip: classes.tooltip, arrow: classes.arrow}}
          >
            <div
              className={classes.centered}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              <FiberManualRecordIcon className={classes.errorBackground}/>
              <ErrorIcon className={classes.error}/>
            </div>
          </Tooltip> :
          null
        }
        {props.status === 'waiting' ?
          <CircularProgress size={20} className={classes.progress}/>:null
        }
      </div>
      {props.leftIndicator ? null :
        <ActionComponent
          type={props.type}
          component={props.childComponent}
          handleAction={handleAction}
          style={{width: props.symmetrical ? '100%' : null}}
          disabled={isDisabled()}
          {...parseProps()}
        >
          {props.children}
        </ActionComponent>
      }
      {/* {props.children}
      <ChildComponent
        style={{width: props.symmetrical ? '100%' : null}}
        disabled={isDisabled()}
        onClick={handleClick}
        {...parseProps()}
      >
        {props.children}
      </ChildComponent> */}
      {
        props.symmetrical && !props.leftIndicator ?
          <div style={{width: '30px'}}></div>:
          null
      }
    </div>
  )
}

const ActionComponent = (props) => {
  const parseProps = () => {
    const {
      type,
      component,
      handleAction,
      ...formattedProps
    } = props
    return formattedProps
  }
  const Component = props.component

  return (
    <>
      {props.type === 'onClick' ?
        <Component
          {...parseProps()}
          onClick={props.handleAction}
        >
        </Component> :
        null
      }
      {props.type === 'text' ?
        <Component
          {...parseProps()}
          onBlur={(ev)=> {
            props.handleAction(ev)
          }}
        >
        </Component> :
        null
      }
    </>
  )
}

export { RespComponent }