import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'

const useStyles = makeStyles(theme => ({
  paper: {
    // overflow: 'auto',
    width:'90%',
    height:'90%',
    backgroundColor: theme.palette.custom.primary,
  },
  dialog: {
    maxHeight: '90%',
  },
  dialogContent: {
    overflowX: 'hidden',
    padding: '0px 0px 0px 17px',
  },
  dialogHeader: {
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
    // marginBottom: '10px',
  },
  dialogFooter: {
    backgroundColor: '#C9C9C9',
    display:'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 10,
    padding: '8px',
    width: '100%',
    height: 52,
  },
  dialogRoot: {
    borderRadius: 0,
  },
}))

export default function GenericDialog(props) {
  const classes = useStyles()

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      className={classes.dialog}
      classes={{paper: props.dialogRoot}}
      keepMounted={props.keepMounted}
      TransitionProps={props.TransitionProps}
      maxWidth='xl'
      BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, .7)' } }}
    >
      <div style={{display: 'flex', flexDirection: 'column', gap: 3, overflow: 'hidden'}}>
        {props.title ?
          <>
            <div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between', width: '100%'}}>
              <div style={{padding: '6px 10px 0px', backgroundColor: props.titleColor}}>
                {props.title}
              </div>
              <div>
                {props.headerButtons}
              </div>
            </div>
            <Divider style={{margin: '0px 10px'}}/>
          </>:
          null}
        <div style={{padding: '10px', overflowY: 'auto', overflowX: 'hidden', flexGrow: 1}}>
          {props.children}
        </div>
        <div className={classes.dialogFooter}>
          {props.buttons}
        </div>
      </div>
    </Dialog>
  )
}
